


































































import Vue from "vue";
import Component from "vue-class-component";
import i18n from '@/locale/i18n';
import { Prop } from 'vue-property-decorator';
import { WorkspaceDto } from '@/application/dtos/core/workspaces/WorkspaceDto';
import { WorkspaceType } from '@/application/enums/core/tenants/WorkspaceType';
import EmptyState from '@/components/ui/emptyState/EmptyState.vue';

@Component({
  components: {
    EmptyState
  },
})
export default class WorkspacesListAndTable extends Vue {
  @Prop({}) items!: WorkspaceDto[];
  headers = [
    {
      title: i18n.t("models.workspace.object"),
    },
    {
      title: i18n.t("models.workspace.type"),
    },
    {
      title: i18n.t("models.user.plural"),
    },
    {
      title: i18n.t("shared.actions"),
    },
  ];

  workspaceType(item: WorkspaceDto) {
    return this.$t("app.workspaces.types." + WorkspaceType[item.type]);
  }
  userEmails(item: WorkspaceDto) {
    return item.users.map((f) => f.user?.firstName).join(", ");
  }
}
