







































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ContractActivityDto } from "@/application/dtos/app/contracts/ContractActivityDto";
import { ContractActivityType } from "@/application/enums/app/contracts/ContractActivityType";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {},
})
export default class ContractActivity extends Vue {
  @Prop({}) items!: ContractActivityDto[];
  getActivityTitle(activity: ContractActivityDto) {
    switch (activity.type) {
      case ContractActivityType.CREATED:
        return this.$t("app.contracts.activity.types.CREATED");
    }
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
  get sortedActivity() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return x.createdAt > y.createdAt ? 1 : -1;
      }
      return 1;
    });
  }
}
