































import Vue from "vue";
import Component from "vue-class-component";
import SlideOver from "../slideOvers/SlideOver.vue";
import { Prop } from "vue-property-decorator";
// @ts-ignore
import PictureInput from "vue-picture-input";

@Component({
  components: {
    PictureInput,
    SlideOver
  }
})
export default class UploadImage extends Vue {
  @Prop() title!: string;
  @Prop() image!: string;
  mounted() {
    // @ts-ignore
    this.$refs.pictureInput.image = this.image;
  }
  onChange() {
    if (this.loadedImage) {
      this.$emit("loaded", this.loadedImage);
    }
  }
  close() {
    this.$emit("close");
  }
  get loadedImage() {
    // @ts-ignore
    return this.$refs.pictureInput.image;
  }
}
