var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative overflow-hidden bg-white dark:bg-gray-900 text-coolGray-800 dark:text-blueGray-200"},[_c('Hero'),_c('LogoCloudsVariantCustom'),_c('FeaturesVariantCards',{attrs:{"item":{
    style: 'cards',
    topText: 'VUE2 + TAILWIND CSS',
    headline: 'Frontend Features',
    subheadline: 'Everything you need get started building a complete SaaS application.',
    grid: {
      columns: '3',
      gap: 'md',
    },
    items: [
      {
        name: 'Components',
        description: 'Buttons, Links, Breadcrumbs, Modals, Image/Document Uploaders, Banners and more.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/Components.jpg',
        link: { text: 'View all components', href: '/components' },
      },
      {
        name: 'Tailwind CSS',
        description: 'Improve the current design with your own design skills. Comes with a custom theme color and Dark Mode.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/Tailwind%20CSS.jpg',
      },
      {
        name: 'SaaS pages/routes/views',
        description:
          'Front (Landing, Pricing...), Account (Login, Register...), Admin (Tenants, Users...), Core (Dashboard, Links...) and Sample app (Contracts, Employees...) views.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/Router.jpg',
        link: { text: 'Explore all views', href: '/docs/pages' },
      },
      {
        name: 'Vue2 Class Components',
        description: 'TypeScript friendly classes with vue-class-components.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/vue2-class-components.jpg',
      },
      {
        name: 'i18n',
        description: 'Localize your app, comes with English and Spanish locale keys. Recommended extension: i18n-ally.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/i18n.jpg',
      },
      {
        name: 'Fake API (sandbox env)',
        description: 'Love the design-first approach? Just set your environment to sandbox and call fake API implementations.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/Fake%20API.jpg',
      },
      {
        name: 'Persisted Store',
        description: 'Modular persisted store to remember your users previous state.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/Store.jpg',
      },
      {
        name: 'ESLint',
        description: 'Lint your code with your own rules, using @typescript-eslint.',
        img: 'https://yahooder.sirv.com/saasfrontends/features/ESLint.jpg',
      } ],
  }}}),_c('FeaturesVariantCards',{attrs:{"item":{
      style: 'cards',
      topText: '.NET',
      headline: 'Backend Features',
      subheadline: 'Multi-organizations/tenants, clean architecture, integration tests, Stripe subscriptions and Postmark Emails.',
      grid: {
        columns: '3',
        gap: 'md',
      },
      items: [
        {
          name: '.NET',
          description: 'Web API built on top of Microsoft´s framework, .NET.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/_NET.jpg',
        },
        {
          name: 'Member management',
          description: 'Invite users as Owner, Admin, Member or Guest. Or add your custom roles.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/MemberManagement.jpg',
        },
        {
          name: 'Clean Architecture',
          description: 'Solution split the project into multiple `layers`: Domain, Application, Infrastructre, Tests and ClientApp (frontend).',
          img: 'https://yahooder.sirv.com/saasfrontends/features/CleanArchitecture.jpg',
        },
        {
          name: 'Stripe Subscriptions',
          description: 'Configure your plans and pricing the way it makes sense for your SaaS.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/Stripe.jpg',
          link: { text: 'Click here to setup.', href: '/admin/pricing' },
        },
        {
          name: 'Integration Tests',
          description: 'Almost every controller method has its own integration test.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/Tests.jpg',
        },
        {
          name: 'Postmark Emails',
          description: 'Setup your custom email tempaltes with Postmark.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/Postmark.jpg',
          link: { text: 'Click here to setup.', href: '/admin/emails' },
        },
        {
          name: 'Multi-organization & multi-workspace',
          description: 'Designed to have tenants per account/subscription. Each tenant can have 1 or more workspaces.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/MultiTenancy.jpg',
        },
        {
          name: 'Entity Framework',
          description: 'EF Core supported databases. Current implementation: PostgreSql.',
          img: 'https://yahooder.sirv.com/saasfrontends/features/EntityFramework.jpg',
        } ],
    }}}),_c('JoinNow'),_c('Faq'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }