


































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import services from "@/services";
import UploadDocument from "@/components/ui/uploaders/UploadDocument.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import { EmployeeDto } from "@/application/dtos/app/employees/EmployeeDto";
import Loading from "@/components/ui/loaders/Loading.vue";
import ButtonPrimary from "@/components/ui/buttons/ButtonPrimary.vue";
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";

@Component({
  components: {
    UploadDocument,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    Loading,
    ButtonPrimary,
    ButtonSecondary
  },
})
export default class AddEmployees extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmCreate: ConfirmModal;
  };

  employees: EmployeeDto[] = [];
  employeesFile = "";

  showImportEmployees = true;
  importingEmployees = false;

  loading = false;

  created() {
    if (process.env.VUE_APP_SERVICE === 'sandbox') {
      this.employees.push({
        id: undefined,
        firstName: "Luna",
        lastName: "Davis",
        email: "luna.davis@company.com",
      });
    }
    this.addEmployee();
  }
  removeEmployee(index) {
    this.employees = this.employees.filter((_x, i) => i !== index);
    if (this.employees.length === 0) {
      this.employeesFile = "";
    }
  }
  save() {
    this.$refs.confirmCreate.show(this.$t("shared.confirmSave"), this.$t("shared.confirm"), this.$t("shared.back"));
  }
  cancel() {
    this.$router.push("/app/employees");
  }
  confirmSave() {
    this.loading = true;
    if (this.employees.length > 0) {
      services.employees
        .createMultiple({
          employees: this.employees,
        })
        .then(() => {
          this.$refs.successModal.show(this.$t("shared.saved"));
        })
        .catch((error) => {
          this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  goToProfile() {
    this.$router.push("/app/employees");
  }
  addEmployee() {
    this.employees.push({
      id: undefined,
      firstName: "",
      lastName: "",
      email: "",
    });
    if (this.employees.length > 1) {
      this.$nextTick(() => {
        this.$refs["employee-first-name-" + (this.employees.length - 1)][0].focus();
      });
    }
  }
  downloadEmployeesFileTemplate() {
    const rows = [[
      this.$t("models.employee.firstName").toString().toUpperCase(),
      this.$t("models.employee.lastName").toString().toUpperCase(),
      this.$t("models.employee.email").toString().toUpperCase()]
    ];

    const csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "plantilla.csv");
    document.body.appendChild(link);

    link.click();
  }
  droppedEmployeesFile(filesBase64: FileBase64[], files: File[]) {
    if (files.length > 0) {
      this.employeesFile = filesBase64[0].base64;
      const employeesFile = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const employees = this.csvToArray(e.target.result);
        employees.forEach((employeeObject) => {
          const employee: string[] = Object.values(employeeObject);
          const firstName = employee.length > 0 ? employee[0].toString().replace("\r", "") : "";
          if (firstName === "NOMBRE" || firstName === "FIRST NAME" || firstName === "NAME") {
            return;
          }
          const lastName = employee.length > 1 ? employee[1].toString().replace("\r", "") : "";
          const email = employee.length > 2 ? employee[2].toString().replace("\r", "") : "";
          this.employees.push({
            id: undefined,
            firstName,
            lastName,
            email,
          });
        });
        this.employees = this.employees.filter((f) => f.firstName && f.lastName);
        this.clearEmptyEmployees();
      };
      reader.readAsText(employeesFile);
    }
  }
  clearEmptyEmployees() {
    this.employees.forEach((employee, index) => {
      if (!employee.firstName || employee.firstName.trim() === "") {
        this.employees = this.employees.filter((_x, i) => i !== index);
      }
    });
  }
  csvToArray(str, delimiter = ",") {
    const headers: string[] = str.slice(0, str.indexOf("\n")).split(delimiter);

    let rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const header = headers[0].trim().toUpperCase();
    if (header === "NOMBRE" || header === "FIRST NAME" || header === "NAME") {
      rows = str.split("\n");
    }
    const arr = rows.map((row) => {
      const values = row.split(delimiter);
      const el = headers.reduce((object, _header, index) => {
        object[index] = values[index];
        return object;
      }, {});
      return el;
    });
    return arr;
  }
}
