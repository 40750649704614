var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.withCurrentPlan)?_c('div',{staticClass:"space-y-2 sm:space-y-0 sm:flex items-center sm:space-x-2 justify-between"},[_c('h3',{staticClass:"leading-5 text-gray-900 truncate"},[(_vm.loading)?_c('span',{staticClass:"leading-5"},[_vm._v(_vm._s(_vm.$t("shared.loading"))+"...")]):(_vm.currentProduct)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("settings.subscription.current"))+" "),_c('router-link',{staticClass:"leading-5 font-bold hover:underline hover:text-theme-600",attrs:{"to":"/app/settings/subscription"}},[_vm._v(_vm._s(_vm.$t(_vm.currentProduct.subscriptionProduct.title)))])],1):(!_vm.loading)?_c('span',{staticClass:"ml-1 text-sm leading-5 font-bold text-gray-500"},[_vm._v("("+_vm._s(_vm.$t("settings.subscription.noActivePlan"))+")")]):_vm._e()])]):_vm._e(),_c('dl',{staticClass:"grid gap-5",class:[
        _vm.cols,
        {
          'mt-2 ': _vm.withCurrentPlan,
        } ]},[_c('div',{staticClass:"bg-white px-4 py-5 border border-gray-300 shadow-md rounded-lg overflow-hidden sm:p-6",class:{
          'bg-rose-50 border-rose-300 hover:bg-rose-100 cursor-pointer': _vm.billableStatus(_vm.maxLinksRemaining) === 0,
          'bg-yellow-50 border-yellow-300 hover:bg-yellow-100 cursor-pointer': _vm.billableStatus(_vm.maxLinksRemaining) === 1,
          'bg-white': _vm.billableStatus(_vm.maxLinksRemaining) === 2,
          'bg-teal-50 border-teal-300 hover:bg-teal-100 cursor-pointer': _vm.billableStatus(_vm.maxLinksRemaining) === 3,
        }},[_c('dt',{staticClass:"text-sm font-medium text-gray-500 truncate"},[_vm._v(_vm._s(_vm.$t("models.link.plural")))]),_c('dd',{staticClass:"mt-1 text-xl font-semibold text-gray-900"},[(_vm.loading)?_c('span',[_vm._v("...")]):_c('span',[(_vm.links)?_c('span',[_vm._v(_vm._s(_vm.links))]):_c('span',[_vm._v("0")]),_vm._v(" / "),(_vm.currentProduct)?_c('span',[_vm._v(_vm._s(_vm.currentProduct.maxLinks))]):_c('span',{staticClass:"text-gray-500"},[_vm._v("0")])])])]),_c('router-link',{staticClass:"bg-white px-4 py-5 border border-gray-300 shadow-md rounded-lg overflow-hidden sm:p-6 hover:bg-gray-50",class:{
          'bg-rose-50 border-rose-300 hover:bg-rose-100 cursor-pointer': _vm.billableStatus(_vm.maxDocumentsRemaining) === 0,
          'bg-yellow-50 border-yellow-300 hover:bg-yellow-100 cursor-pointer': _vm.billableStatus(_vm.maxDocumentsRemaining) === 1,
          'bg-white': _vm.billableStatus(_vm.maxDocumentsRemaining) === 2,
          'bg-teal-50 border-teal-300 hover:bg-teal-100 cursor-pointer': _vm.billableStatus(_vm.maxDocumentsRemaining) === 3,
        },attrs:{"to":"/app/contracts/pending"}},[_c('dt',{staticClass:"text-sm font-medium text-gray-500 truncate"},[_vm._v(_vm._s(_vm.$t("models.contract.plural")))]),_c('dd',{staticClass:"mt-1 text-xl font-semibold text-gray-900"},[(_vm.loading)?_c('span',[_vm._v("...")]):_c('span',[(_vm.usage && _vm.usage.contracts)?_c('span',[_vm._v(_vm._s(_vm.usage.contracts))]):_c('span',[_vm._v("0")]),_vm._v(" / "),(_vm.currentProduct)?_c('span',[_vm._v(_vm._s(_vm.currentProduct.monthlyContracts))]):_c('span',{staticClass:"text-gray-500"},[_vm._v("0")])])])]),_c('router-link',{staticClass:"bg-white px-4 py-5 border border-gray-300 shadow-md rounded-lg overflow-hidden sm:p-6 hover:bg-gray-50",class:{
          'bg-rose-50 border-rose-300 hover:bg-rose-100 cursor-pointer': _vm.billableStatus(_vm.maxWorkspacesRemaining) === 0,
          'bg-yellow-50 border-yellow-300 hover:bg-yellow-100 cursor-pointer': _vm.billableStatus(_vm.maxWorkspacesRemaining) === 1,
          'bg-white': _vm.billableStatus(_vm.maxWorkspacesRemaining) === 2,
          'bg-teal-50 border-teal-300 hover:bg-teal-100 cursor-pointer': _vm.billableStatus(_vm.maxWorkspacesRemaining) === 3,
        },attrs:{"to":"/app/settings/workspaces"}},[_c('dt',{staticClass:"text-sm font-medium text-gray-500 truncate"},[_vm._v(_vm._s(_vm.$t("models.workspace.plural")))]),_c('dd',{staticClass:"mt-1 text-xl font-semibold text-gray-900"},[(_vm.loading)?_c('span',[_vm._v("...")]):_c('span',[(_vm.usage)?_c('span',[_vm._v(_vm._s(_vm.workspaces.length))]):_c('span',[_vm._v("0")]),_vm._v(" / "),(_vm.currentProduct)?_c('span',[_vm._v(_vm._s(_vm.currentProduct.maxWorkspaces))]):_c('span',{staticClass:"text-gray-500"},[_vm._v("0")])])])]),_c('router-link',{staticClass:"bg-white px-4 py-5 border border-gray-300 shadow-md rounded-lg overflow-hidden sm:p-6 hover:bg-gray-50",class:{
          'bg-rose-50 border-rose-300 hover:bg-rose-100 cursor-pointer': _vm.billableStatus(_vm.maxUsersRemaining) === 0,
          'bg-yellow-50 border-yellow-300 hover:bg-yellow-100 cursor-pointer': _vm.billableStatus(_vm.maxUsersRemaining) === 1,
          'bg-white': _vm.billableStatus(_vm.maxUsersRemaining) === 2,
          'bg-teal-50 border-teal-300 hover:bg-teal-100 cursor-pointer': _vm.billableStatus(_vm.maxUsersRemaining) === 3,
        },attrs:{"to":"/app/settings/members"}},[_c('dt',{staticClass:"text-sm font-medium text-gray-500 truncate"},[_vm._v(_vm._s(_vm.$t("models.user.plural")))]),_c('dd',{staticClass:"mt-1 text-xl font-semibold text-gray-900"},[(_vm.loading)?_c('span',[_vm._v("...")]):_c('span',[(_vm.usage)?_c('span',[_vm._v(_vm._s(_vm.users.length))]):_c('span',[_vm._v("0")]),_vm._v(" / "),(_vm.currentProduct)?_c('span',[_vm._v(_vm._s(_vm.currentProduct.maxUsers))]):_c('span',{staticClass:"text-gray-500"},[_vm._v("0")])])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }