














































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ContractMemberDto } from "@/application/dtos/app/contracts/ContractMemberDto";
import store from "@/store";

@Component({
  components: {},
})
export default class ContractMembers extends Vue {
  @Prop({}) items!: ContractMemberDto[];
  isCurrentMember(member: ContractMemberDto) {
    return store.state.account.user?.email === member.user.email;
  }
  get currentEmail() {
    return store.state.account.user?.email ?? "";
  }
  get sortedItems() {
    return this.items.sort((x, y) => {
      return x.user.firstName > y.user.firstName ? 1 : -1;
    });
  }
}
