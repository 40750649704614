






























































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import tinyEventBus from "@/plugins/tinyEventBus";
import store from "@/store";
import { WorkspaceType } from "@/application/enums/core/tenants/WorkspaceType";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import { UserDto } from "@/application/dtos/core/users/UserDto";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import SelectUsers from "../users/SelectUsers.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    SelectUsers,
    ConfirmModal,
    Loading,
  },
})
export default class EditWorkspace extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectUsers: SelectUsers;
    confirmRemove: ConfirmModal;
  };
  id = "";
  item: WorkspaceDto | null = null;
  loading = false;
  name = "";
  type: WorkspaceType = WorkspaceType.PRIVATE;
  businessMainActivity = "";
  registrationNumber = "";
  registrationDate: Date | null = null;
  users: UserDto[] = [];
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.loading = true;
    services.workspaces
      .get(this.id)
      .then((response) => {
        this.item = response;
        this.name = response.name;
        this.type = response.type;
        this.businessMainActivity = response.businessMainActivity;
        this.registrationNumber = response.registrationNumber;
        if (response.registrationDate) {
          this.registrationDate = new Date(DateUtils.dateYMD(response.registrationDate));
        }
        this.users = [];
        response.users.forEach((element) => {
          if (element.user) {
            this.users.push(element.user);
          }
        });
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  close() {
    this.$router.push("/app/settings/workspaces");
  }
  save() {
    if (this.users.length === 0) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("account.tenant.workspaces.errors.atLeastOneUser"));
      return;
    }
    this.loading = true;
    services.workspaces
      .update(this.id, {
        name: this.name,
        type: this.type,
        businessMainActivity: this.businessMainActivity,
        registrationNumber: this.registrationNumber,
        registrationDate: this.registrationDate,
        users: this.users,
      })
      .then(() => {
        tinyEventBus().emitter.emit("workspace-saved");
        this.$refs.successModal.show(this.$t("shared.updated"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  remove() {
    this.$refs.confirmRemove.show(this.$t("shared.confirmDelete"), this.$t("shared.delete"), this.$t("shared.cancel"), this.$t("shared.warningCannotUndo"));
  }
  yesRemove() {
    if (this.currentRole === TenantUserRole.MEMBER || this.currentRole === TenantUserRole.GUEST) {
      this.$refs.errorModal.show(this.$t("account.tenant.onlyAdmin"));
    } else {
      this.loading = true;
      services.workspaces
        .delete(this.id)
        .then(() => {
          tinyEventBus().emitter.emit("workspace-deleted", this.item);
          this.$router.push("/app/settings/workspaces");
        })
        .catch((error) => {
          this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  selectWorkspaceUsers() {
    this.$refs.selectUsers.show(this.users.map((f) => f.id));
  }
  selectedUsers(items: UserDto[]) {
    this.users = items;
  }
  changedType(e) {
    const type: WorkspaceType = Number(e.target.value);
    this.type = type;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get currentUsersDescription() {
    if (this.users.length === 0) {
      return this.$t("app.users.seletAtLeastOne");
    }
    return this.users.map((f) => `${f.firstName} (${f.email})`).join(", ");
  }
}
