var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{attrs:{"menu":[
      {
        title: _vm.$t('models.employee.plural'),
        routePath: '/app/employees'
      },
      {
        title: _vm.$t('shared.new')
      }
    ]}}),_c('div',{staticClass:"bg-white shadow-sm border-b border-gray-300 w-full py-2"},[_c('div',{staticClass:"mx-auto max-w-5xl xl:max-w-7xl flex items-center justify-between px-4 sm:px-6 lg:px-8 space-x-2"},[_c('h2',{staticClass:"flex-1 font-bold flex items-center truncate"},[_vm._v(_vm._s(_vm.$t("app.employees.new.multiple")))])])]),_c('div',{staticClass:"max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-6"},[_c('AddEmployees')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }