















import Vue from "vue";
import services from "@/services";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import store from "@/store";
import Component from 'vue-class-component';

@Component({})
export default class ProvidersUsage extends Vue {
  loading = false;
  mounted() {
    this.loading = true;
    services.tenants.getCurrentUsage(AppUsageType.CLIENTS).finally(() => {
      this.loading = false;
    });
  }
  get clients() {
    return store.state.app.usage.clients;
  }
}
