















import Vue from "vue";
import services from "@/services";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import store from "@/store";
import { Component } from "vue-property-decorator";

@Component({})
export default class Providers extends Vue {
  loading = false;
  mounted() {
    this.loading = true;
    services.tenants.getCurrentUsage(AppUsageType.PROVIDERS).finally(() => {
      this.loading = false;
    });
  }
  get providers() {
    return store.state.app.usage.providers;
  }
}
