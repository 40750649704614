
























































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import tinyEventBus from "@/plugins/tinyEventBus";
import store from "@/store";
import WarningBannner from "@/components/ui/banners/WarningBanner.vue";
import { WorkspaceType } from "@/application/enums/core/tenants/WorkspaceType";
import { UserDto } from "@/application/dtos/core/users/UserDto";
import Loading from "@/components/ui/loaders/Loading.vue";
import SelectUsers from "../users/SelectUsers.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    WarningBannner,
    SelectUsers,
    Loading,
  },
})
export default class NewWorkspace extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectUsers: SelectUsers;
    inputName: HTMLInputElement;
  };
  loading = false;
  name = "";
  type: WorkspaceType = WorkspaceType.PRIVATE;
  businessMainActivity = "";
  registrationNumber = "";
  registrationDate: Date | null = null;
  users: UserDto[] = [];
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.inputName) {
        this.$refs.inputName.focus();
        this.$refs.inputName.select();
      }
    });
  }
  close() {
    this.$router.push("/app/settings/workspaces");
  }
  save() {
    if (this.users.length === 0) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("account.tenant.workspaces.errors.atLeastOneUser"));
      return;
    }
    this.loading = true;
    services.workspaces
      .create({
        name: this.name,
        type: this.type,
        businessMainActivity: this.businessMainActivity,
        registrationNumber: this.registrationNumber,
        registrationDate: this.registrationDate,
        users: this.users,
      })
      .then((response) => {
        tinyEventBus().emitter.emit("workspace-added", response);
        this.$refs.successModal.show(this.$t("shared.added"), this.$t("app.workspaces.added"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selectWorkspaceUsers() {
    this.$refs.selectUsers.show([]);
  }
  selectedUsers(items: UserDto[]) {
    this.users = items;
  }
  changedType(e) {
    const type: WorkspaceType = Number(e.target.value);
    this.type = type;
  }
  get currentUsersDescription() {
    if (this.users.length === 0) {
      return this.$t("app.users.seletAtLeastOne");
    }
    return this.users.map((f) => `${f.firstName} (${f.email})`).join(", ");
  }
  get maxWorkspaces(): number {
    return store.state.app.features.maxWorkspaces;
  }
  get maxWorkspacesReached() {
    if (this.maxWorkspaces > 0 && store.state.tenant.workspaces.length >= this.maxWorkspaces) {
      return true;
    }
    return false;
  }
}
