
















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import i18n from '@/locale/i18n';
import { TenantUserRole } from '@/application/enums/core/tenants/TenantUserRole';
import { SideBarItem } from '@/application/sidebar/SidebarItem';
import { AdminSidebar } from '@/application/sidebar/AdminSidebar';
import { AppSidebar } from '@/application/sidebar/AppSidebar';
import SidebarIcon from '@/components/layouts/icons/SidebarIcon.vue';

@Component({
  metaInfo: {
    title: i18n.t("admin.navigation.title").toString(),
  },
  components: {
    SidebarIcon
  }
})
export default class Navigation extends Vue {
  items: SideBarItem[] = []
  roles: TenantUserRole[] = []
  searchInput = "";

  mounted() {
    AdminSidebar.forEach(admin => {
      admin.items?.forEach(item => {
        this.items.push(item);
      });
    });
    AppSidebar.forEach(app => {
      app.items?.forEach(item => {
        this.items.push(item);
      });
    });
    const roleKeys = Object.keys(TenantUserRole)
      .filter(key => !isNaN(Number(key)));
    roleKeys.forEach(role => {
      this.roles.push(Number(role))
    });
  }
  roleName(role) {
    return i18n.t("settings.profile.roles." + TenantUserRole[role]);
  }
  roleHasAccess(item: SideBarItem, role): boolean {
    return !item.path.includes("/admin") && this.allowRole(item, role);
  }
  allowRole(item: SideBarItem, role: TenantUserRole) {
    return (!item.userRoles || item.userRoles.includes(role));
  }
  get filteredItems(): any[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      (f) =>
        f.title?.toString()?.toUpperCase().includes(this.searchInput.toUpperCase())
        || f.title?.toString()?.toUpperCase().includes(this.searchInput.toUpperCase())
    );
    return items;
  }
}
