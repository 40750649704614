















































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { ContractDto } from "@/application/dtos/app/contracts/ContractDto";
import ContractsListAndTable from "./ContractsListAndTable.vue";
import { ContractStatusFilter } from "@/application/contracts/app/contracts/ContractStatusFilter";
import Loading from "@/components/ui/loaders/Loading.vue";
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ContractsListAndTable,
    Loading,
  },
})
export default class ContractsList extends Vue {
  @Prop({}) filter!: ContractStatusFilter;

  loading = false;
  items: ContractDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.contracts
      .getAllByStatusFilter(this.filter)
      .then((response) => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get filteredItems(): ContractDto[] {
    if (!this.items) {
      return [];
    }
    return this.items.filter((f) => f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) || f.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.description?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.link?.providerWorkspace.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.link?.clientWorkspace.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.createdByUser?.email?.toString().toUpperCase().includes(this.searchInput.toUpperCase()));
  }
}
