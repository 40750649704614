



































import Vue from "vue";
import Component from "vue-class-component";
import EmployeesList from "@/components/app/employees/EmployeesList.vue";
import ButtonPrimary from "@/components/ui/buttons/ButtonPrimary.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("models.employee.plural").toString()
  },
  components: {
    EmployeesList,
    ButtonPrimary,
  },
})
export default class Employees extends Vue { }
