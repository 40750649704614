














































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { TenantUserDto } from "@/application/dtos/core/tenants/TenantUserDto";
import { TenantJoinSettingsDto } from "@/application/dtos/core/tenants/TenantJoinSettingsDto";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import { TenantUserStatus } from "@/application/enums/core/tenants/TenantUserStatus";
import i18n from "@/locale/i18n";
import tinyEventBus from "@/plugins/tinyEventBus";
import store from "@/store";
import WarningBannner from "@/components/ui/banners/WarningBanner.vue";
import services from "@/services";
import { UserType } from "@/application/enums/core/users/UserType";
import Loading from "@/components/ui/loaders/Loading.vue";
import MembersListAndTable from "@/components/core/settings/members/MembersListAndTable.vue";

@Component({
  metaInfo: {
    title: i18n.t("settings.members.title").toString()
  },
  components: {
    ConfirmModal,
    ErrorModal,
    WarningBannner,
    Loading,
    MembersListAndTable
  },
})
export default class Members extends Vue {
  $refs!: {
    confirmAcceptUser: ConfirmModal;
    errorModal: ErrorModal;
    confirmUpgrade: ConfirmModal;
  };
  @Prop({ default: "Miembros" }) readonly title!: string;
  loading = false;
  searchInput = "";

  items = [] as TenantUserDto[];
  tenantJoinSettings: any = {};
  enableLink = false;
  enablePublicUrl = false;
  requireAcceptance = false;
  acceptedUser!: TenantUserDto;
  acceptUserEmail = "";
  created() {
    this.acceptUserEmail = this.$route.query.au?.toString() ?? "";
  }
  mounted() {
    tinyEventBus().emitter.on("user-added", () => this.reload());
    tinyEventBus().emitter.on("user-saved", () => this.reload());
    tinyEventBus().emitter.on("user-deleted", () => this.reload());

    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("user-deleted");
    tinyEventBus().emitter.off("user-added");
    tinyEventBus().emitter.off("user-saved");
  }
  async reload() {
    this.loading = true;
    const promises: any[] = [];
    promises.push(services.tenants.getFeatures());
    promises.push(services.tenantUserInvitations.getInvitationSettings().then((response: TenantJoinSettingsDto) => {
      this.tenantJoinSettings = response;
      this.enableLink = this.tenantJoinSettings.linkActive;
      this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
      this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
    }));
    promises.push(services.tenantUsers
      .getAll()
      .then((response: TenantUserDto[]) => {
        this.items = [];
        response.forEach((element) => {
          this.items.push(element);
        });

        if (this.acceptUserEmail) {
          const user = this.items.find((f) => f.email === this.acceptUserEmail);
          if (user && user.status === TenantUserStatus.PENDING_ACCEPTANCE) {
            this.acceptUser(user, true);
          }
        }
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      }));

    Promise.all(promises).finally(() => {
      this.loading = false;
    })
  }
  // saved(data) {
  //   const idx = this.items.findIndex((f) => f.id === data.id);
  //   this.items[idx] = data;
  // }
  // deleted(data) {
  //   this.items = this.items.filter((f) => f.id !== data.id);
  // }
  yesUpdateSubscription() {
    this.$router.push("/app/settings/subscription");
  }
  yesAcceptUser() {
    if (this.isOwnerOrAdmin && this.acceptedUser) {
      services.tenantUserInvitations
        .acceptUser(this.acceptedUser)
        .then(() => {
          this.reload();
        })
        .catch((error) => {
          this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
        });
    }
  }
  acceptUser(item: TenantUserDto, accept: boolean) {
    item.accepted = accept;
    this.acceptedUser = item;
    this.$refs.confirmAcceptUser.show(this.$t("shared.accept?", [item.email]).toString(), this.$t("shared.accept").toString(), this.$t("shared.cancel").toString());
  }
  get isOwnerOrAdmin(): boolean {
    return this.currentRole === TenantUserRole.OWNER || this.currentRole === TenantUserRole.ADMIN;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get maxUsers(): number {
    if (store.state.account.user?.type === UserType.Admin) {
      return 0;
    }
    return store.state.app.features.maxUsers;
  }
  get maxUsersReached() {
    return this.maxUsers > 0 && store.state.tenant.members.length >= this.maxUsers;
  }
  get filteredItems(): TenantUserDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter((f) => f.firstName?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.lastName?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.email?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.phone?.toString().toUpperCase().includes(this.searchInput.toUpperCase()));

    return items;
  }
  get sortedItems() {
    if (!this.items) {
      return [];
    }
    const items = this.filteredItems.sort((x, y) => {
      return x.role > y.role ? -1 : 1;
    });
    return items.sort((x, y) => {
      return x.role > y.role ? 1 : -1;
    });
  }
}
