













import Vue from "vue";
import Component from "vue-class-component";
import Tabs, { TabItem } from "@/components/ui/tabs/Tabs.vue"
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("app.navbar.settings").toString()
  },
  components: {
    Tabs,
  },
})
export default class Contracts extends Vue {
  tabs: TabItem[] = [];
  mounted() {
    if (this.$route.name?.toString() === "settings") {
      this.$router.push("/app/settings/profile");
    }
    this.tabs = [
      {
        name: this.$t("settings.profile.profileTitle"),
        routePath: "/app/settings/profile",
      },
      {
        name: this.$t("models.workspace.plural"),
        routePath: "/app/settings/workspaces",
      },
      {
        name: this.$t("settings.members.title"),
        routePath: "/app/settings/members",
      },
      {
        name: this.$t("settings.subscription.title"),
        routePath: "/app/settings/subscription",
      },
      {
        name: this.$t("settings.tenant.title"),
        routePath: "/app/settings/tenant",
      },
    ];
  }
}
