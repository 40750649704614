















import Vue from "vue";
import services from "@/services";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import store from "@/store";
import Component from 'vue-class-component';

@Component({})
export default class Providers extends Vue {
  loading = false;
  mounted() {
    this.loading = true;
    services.tenants.getCurrentUsage(AppUsageType.EMPLOYEES).finally(() => {
      this.loading = false;
    });
  }
  get employees() {
    return store.state.app.usage.employees;
  }
}
