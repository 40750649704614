






























































import Component from "vue-class-component";
import Vue from "vue";
import MySubscriptionPlan from "@/components/core/settings/subscription/MySubscriptionPlan.vue";
import MySubscriptionProducts from "@/components/core/settings/subscription/MySubscriptionProducts.vue";
import MySubscriptionPaymentDetails from "@/components/core/settings/subscription/MySubscriptionPaymentDetails.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import services from '@/services';
import { AppUsageType } from '@/application/enums/app/usages/AppUsageType';
import { SubscriptionPriceDto } from '@/application/dtos/core/subscriptions/SubscriptionPriceDto';
import { TenantProductDto } from '@/application/dtos/core/tenants/TenantProductDto';
import store from '@/store';
import { SubscriptionBillingPeriod } from '@/application/enums/core/subscriptions/SubscriptionBillingPeriod';
import i18n from "@/locale/i18n";

@Component({
  metaInfo: {
    title: i18n.t("app.navbar.subscription").toString()
  },
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    MySubscriptionPlan,
    MySubscriptionProducts,
    MySubscriptionPaymentDetails,
  },
})
export default class MySubscription extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmModal: ConfirmModal;
  };
  mounted() {
    services.subscriptionProducts.getProducts();
    services.tenants.getCurrentUsage(AppUsageType.ALL);
  }
  cancel() {
    this.$refs.confirmModal.show(this.$t("settings.subscription.confirmCancel"));
  }
  confirmCancel() {
    return services.subscriptionManager
      .cancelSubscription()
      .then(() => {
        this.$refs.successModal.show(this.$t("shared.updated"), this.$t("settings.subscription.canceled"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  get products(): TenantProductDto[] | undefined {
    return (store.state.tenant?.subscription?.myProducts as TenantProductDto[]) ?? [];
  }
  get subscription(): SubscriptionPriceDto | undefined {
    const mySubscriptions = this.products?.filter((f) => f.subscriptionPrice.billingPeriod !== SubscriptionBillingPeriod.ONCE);
    if (mySubscriptions && mySubscriptions.length > 0) {
      return mySubscriptions[0].subscriptionPrice;
    } else {
      return undefined;
    }
  }
}
