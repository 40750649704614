



































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import store from "@/store";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import WarningBanner from "@/components/ui/banners/WarningBanner.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import tinyEventBus from "@/plugins/tinyEventBus";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    ErrorModal,
    ConfirmModal,
    SuccessModal,
    WarningBanner,
    Loading
  },
})
export default class NewLink extends Vue {
  $refs!: {
    inputEmail: HTMLInputElement;
    errorModal: ErrorModal;
    confirmCreateLinkModal: ConfirmModal;
    confirmInviteNewUserModal: ConfirmModal;
    successModal: SuccessModal;
  };
  selectType = true;
  asProvider = true;
  imProvider = false;
  loading = false;
  showing = false;
  loadingSearching = false;
  loadingLinking = false;
  email = "";
  workspaceName = "";
  workspace: WorkspaceDto | null = null;
  linkCreated: LinkDto | null = null;
  error = "";
  mounted() {
    this.imProvider = this.asProvider;
  }
  show(asProvider: boolean, selectType: boolean) {
    this.asProvider = asProvider;
    this.selectType = selectType;
    this.workspace = null;
    this.email = "";
    this.workspaceName = "";

    this.showing = true;
    this.$nextTick(() => {
      this.$refs.inputEmail.focus();
      this.$refs.inputEmail.select();
    });
    this.reload();
  }
  reload() {
    this.loading = true;
    const promises: any[] = []
    promises.push(services.tenants.getCurrentUsage(AppUsageType.ALL));
    promises.push(services.tenants.getFeatures());

    Promise.all(promises).finally(() => {
      this.loading = false;
      this.$nextTick(() => {
        if (this.$refs.inputEmail) {
          this.$refs.inputEmail.focus();
          this.$refs.inputEmail.select();
        }
      });
    })
  }
  close() {
    this.showing = false;
  }
  linkUserWorkspace() {
    if (!this.email || this.email.trim() === "" || !this.workspaceName || this.workspaceName.trim() === "") {
      this.$refs.errorModal.show(this.$t("shared.missingFields"));
      return;
    }
    this.error = "";
    this.loadingSearching = true;
    this.workspace = null;
    services.links
      .searchUser(this.email)
      .then(() => {
        this.searchWorkspace();
      })
      .catch(() => {
        this.$refs.confirmInviteNewUserModal.show(this.$t("app.links.accountNotFound"), this.$t("shared.invite"), this.$t("shared.cancel"), this.$t("app.links.invitation.userNotRegistered", [this.email]));
        this.loadingSearching = false;
      });
  }
  searchWorkspace() {
    services.links
      .searchMember(this.email, this.workspaceName)
      .then(() => {
        this.createLink();
      })
      .catch(() => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t("app.links.invitation.notFound", [this.email, this.workspaceName]));
      })
      .finally(() => {
        this.loadingSearching = false;
      });
  }
  createLink() {
    const confirmText = this.workspace?.id ? this.$t("app.links.link") : this.$t("shared.invite");
    const inviteText = this.workspace?.id ? "" : this.$t("app.links.invitation.invite");
    if (this.imProvider) {
      this.$refs.confirmCreateLinkModal?.show(this.$t("app.clients.new.add"), confirmText, this.$t("shared.cancel"), inviteText);
    } else {
      this.$refs.confirmCreateLinkModal?.show(this.$t("app.providers.new.add"), confirmText, this.$t("shared.cancel"), inviteText);
    }
  }
  confirmInviteNewUser() {
    this.loadingLinking = true;
    services.links
      .createInvitation({
        email: this.email,
        workspaceName: this.workspaceName,
        message: "",
        inviteeIsProvider: !this.imProvider,
      })
      .then(() => {
        this.$refs.successModal.show(this.$t("app.links.pending.invitationSent"), this.$t("app.links.pending.invitationSentDescription", [this.email]));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loadingLinking = false;
      });
  }
  confirmCreateLink() {
    this.loadingLinking = true;
    services.links
      .create({
        email: this.email,
        workspaceName: this.workspaceName,
        asProvider: !this.imProvider,
        // permissions: this.permissions
      })
      .then((response) => {
        this.linkCreated = response;
        this.$refs.successModal.show(this.$t("app.links.pending.invitationSent"), this.$t("app.links.invited", [this.email, this.imProvider ? this.$t("models.client.object") : this.$t("models.provider.object"), this.currentWorkspace.name]));
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loadingLinking = false;
      });
  }
  createdLink() {
    tinyEventBus().emitter.emit("reload-links");
    this.$emit("created", this.linkCreated);
    this.close();
  }

  get maxLinks(): number {
    return store.state.app.features.maxLinks;
  }
  get maxLinksReached() {
    return this.maxLinks > 0 && store.state.app.usage.providers + store.state.app.usage.clients >= this.maxLinks;
  }
  get currentWorkspace(): WorkspaceDto {
    return store.state.tenant.currentWorkspace ?? ({} as WorkspaceDto);
  }
}
