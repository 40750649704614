
















































































































import Vue from "vue";
import Component from "vue-class-component";
import { TenantDto } from "@/application/dtos/core/tenants/TenantDto";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import i18n from "@/locale/i18n";
import { SubscriptionBillingPeriod } from "@/application/enums/core/subscriptions/SubscriptionBillingPeriod";
import { SubscriptionPriceDto } from "@/application/dtos/core/subscriptions/SubscriptionPriceDto";
import NumberUtils from "@/utils/shared/NumberUtils";
import services from "@/services";
import Loading from "@/components/ui/loaders/Loading.vue";
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";

@Component({
  metaInfo: {
    title: i18n.t("admin.tenants.title").toString(),
  },
  components: {
    EmptyState,
    Loading,
    ButtonSecondary
  },
})
export default class Tenants extends Vue {
  loading = false;
  items: TenantDto[] = [];
  searchInput = "";
  headers = [
    {
      title: i18n.t("models.tenant.object"),
    },
    {
      title: i18n.t("models.workspace.plural"),
    },
    {
      title: i18n.t("models.tenant.subscription"),
    },
    // {
    //   title: i18n.t("shared.actions")
    // }
  ];
  mounted() {
    this.reload();
  }
  async reload() {
    this.loading = true;
    this.items = [];
    services.tenants
      .adminGetAll()
      .then((response: TenantDto[]) => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  getWorkspaces(item: TenantDto) {
    return item.workspaces?.map((f) => `${f.name}`).join(", ");
  }
  getUsers(item: TenantDto) {
    return item.users?.map((f) => `${f.firstName} ${f.lastName} (${f.email})`).join(", ");
  }
  getProducts(item: TenantDto) {
    return item.products
      ?.map(
        (f) =>
          `${f.subscriptionProduct.tier} - ${this.$t(f.subscriptionProduct.title)} (${NumberUtils.decimalFormat(f.subscriptionPrice.price)} ${f.subscriptionPrice.currency
          }${this.priceBillingPeriod(f.subscriptionPrice)})`
      )
      .join(", ");
  }
  priceBillingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("pricing.once").toString();
    } else {
      return "/" + this.$t("pricing." + SubscriptionBillingPeriod[price.billingPeriod] + "Short");
    }
  }
  get filteredItems(): TenantDto[] {
    if (!this.items) {
      return [];
    }
    return this.items.filter(
      (f) =>
        f.subdomain !== "admin" &&
        (f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) || f.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()))
    );
  }
  get orderedItems(): TenantDto[] {
    if (!this.filteredItems) {
      return [];
    }
    return this.filteredItems.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return (x.createdAt > y.createdAt ? -1 : 1) ?? -1;
      }
      return -1;
    });
  }
}
