























































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
  },
})
export default class LogoCloudsVariantCustom extends Vue {

}

