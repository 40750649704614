










































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import AllLinksListAndTable from "./AllLinksListAndTable.vue";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    AllLinksListAndTable,
    Loading
  }
})
export default class AllLinksList extends Vue {
  loading = false;
  items: LinkDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.items = [];
    this.loading = true;
    services.links
      .getAllLinked()
      .then(response => {
        response.forEach(element => {
          this.items.push(element);
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get filteredItems(): LinkDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
}
