





































































































































import Vue from "vue";
import Component from "vue-class-component";
import { UserDto } from "@/application/dtos/core/users/UserDto";
import i18n from "@/locale/i18n";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import services from "@/services";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import Loading from "@/components/ui/loaders/Loading.vue";
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";
import ButtonTertiary from "@/components/ui/buttons/ButtonTertiary.vue";

@Component({
  metaInfo: {
    title: i18n.t("models.user.plural").toString(),
  },
  components: {
    EmptyState,
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    Loading,
    ButtonSecondary,
    ButtonTertiary
  },
})
export default class Users extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    errorModal: ErrorModal;
    successModal: SuccessModal;
  };
  items: UserDto[] = [];
  loading = false;
  searchInput = "";
  headers = [
    {
      title: i18n.t("models.user.object"),
    },
    {
      title: i18n.t("models.user.tenants"),
    },
    {
      title: i18n.t("shared.actions"),
    },
  ];
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.users
      .adminGetAll()
      .then((response: UserDto[]) => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  impersonate(user: UserDto) {
    services.authentication.impersonate(user.id)
      .catch(error => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  changePassword(user: UserDto) {
    const password = prompt(this.$t("settings.profile.changePassword") + " - " + user.email);
    if (!password || password.length < 8) {
      alert("Set a password with 8 characters minimum");
    } else if (user.type === 0) {
      alert("You cannot change password for admin user");
    } else {
      if (confirm("[ADMINISTRATOR] Update password for user " + user.email + "?")) {
        services.users
          .adminUpdatePassword(user.id, password)
          .then(() => {
            alert("Updated");
          })
          .catch((error) => {
            this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error))
          });
      }
    }
  }
  getUserTenants(user: UserDto) {
    return user.tenants.map((f) => `${f.tenant?.name} (${i18n.t("settings.profile.roles." + TenantUserRole[f.role])})`).join(", ");
  }
  deleteUser(item: UserDto) {
    this.$refs.confirmDelete.value = item;
    this.$refs.confirmDelete.show(this.$t("shared.delete"), this.$t("shared.delete"), this.$t("shared.cancel"), this.$t("admin.users.deleteWarning"));
  }
  confirmDeleteUser(item) {
    this.loading = true;
    services.users
      .adminDelete(item.id)
      .then(() => {
        this.reload();
        this.$refs.successModal.show(this.$t("shared.deleted"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get filteredItems(): UserDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      (f) =>
        f.firstName?.toString()?.toUpperCase().includes(this.searchInput.toUpperCase())
    );

    return items.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return (x.createdAt > y.createdAt ? -1 : 1) ?? -1;
      }
      return -1;
    });
  }
}
