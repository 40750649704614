


















































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import Breadcrumb from "@/components/ui/breadcrumbs/Breadcrumb.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import store from "@/store";
import { ContractMemberRole } from "@/application/enums/app/contracts/ContractMemberRole";
import services from "@/services";
import { ContractDto } from "@/application/dtos/app/contracts/ContractDto";
import IconContract from "@/assets/icons/IconContract.vue";
import IconSign from "@/assets/icons/IconSign.vue";
import IconWorkers from "@/assets/icons/IconWorkers.vue";
import { EmployeeDto } from "@/application/dtos/app/employees/EmployeeDto";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import WarningBanner from "@/components/ui/banners/WarningBanner.vue";
import { AddContractMemberDto } from "@/application/contracts/app/contracts/AddContractMemberDto";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import { AppUsageSummaryDto } from "@/application/dtos/app/usage/AppUsageSummaryDto";
import { TenantFeaturesDto } from "@/application/contracts/core/tenants/TenantFeaturesDto";
import Loading from "@/components/ui/loaders/Loading.vue";
import LinkSelector from "@/components/app/links/selectors/LinkSelector.vue";
import SelectEmployees from "@/components/app/employees/SelectEmployees.vue";
import SelectContractMembers from "@/components/app/contracts/SelectContractMembers.vue";
import UploadDocument from "@/components/ui/uploaders/UploadDocument.vue";
import i18n from "@/locale/i18n";
import PdfViewer from "@/components/ui/pdf/PdfViewer.vue";

@Component({
  metaInfo: {
    title: i18n.t("app.contracts.new.title").toString()
  },
  components: {
    ErrorModal,
    ConfirmModal,
    Breadcrumb,
    UploadDocument,
    PdfViewer,
    IconContract,
    IconSign,
    IconWorkers,
    LinkSelector,
    WarningBanner,
    SelectEmployees,
    SelectContractMembers,
    Loading,
  },
})
export default class NewContract extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    confirmCreate: ConfirmModal;
    inputName: HTMLInputElement;
    selectEmployees: SelectEmployees;
    selectContractMembers: SelectContractMembers;
    linkSelector: LinkSelector;
  };
  name = "";
  link: LinkDto | null = null;
  linkId = "";
  description = "";
  contractFile = "";
  employeesFile = "";
  currentPage = 1;
  pageCount = 0;
  members: AddContractMemberDto[] = [];
  employees: EmployeeDto[] = [];
  loading = false;
  preselectLinkId = "";
  mounted() {
    this.preselectLinkId = this.$route.query?.l?.toString() ?? "";
    if (this.preselectLinkId) {
      services.links.get(this.preselectLinkId).then((link) => {
        this.$refs.linkSelector.select(link);
      });
    }
    this.loadFeatures();
  }
  loadFeatures() {
    services.tenants.getCurrentUsage(AppUsageType.CONTRACTS);
    services.tenants.getFeatures();
  }
  addMember() {
    if (!this.link || !this.link.id) {
      this.$refs.errorModal.show(this.$t("shared.missingFields"), this.$t("app.contracts.errors.linkRequired"));
    } else {
      this.$refs.selectContractMembers.show(
        this.link,
        this.link?.id,
        this.members.map((f) => f.userId)
      );
    }
  }
  addEmployee() {
    this.$refs.selectEmployees.show(this.employees.map((f) => f.id));
  }
  removeFile() {
    this.contractFile = "";
  }
  nextPage(pages) {
    this.currentPage += pages;
  }
  removeMember(index) {
    this.members = this.members.filter((_x, i) => i !== index);
  }
  removeEmployee(index) {
    this.employees = this.employees.filter((_x, i) => i !== index);
    if (this.employees.length === 0) {
      this.employeesFile = "";
    }
  }
  save() {
    if (this.maxContractsReached) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("app.subscription.errors.limitReachedContracts"));
    } else if (!this.link || !this.link.id) {
      this.$refs.errorModal.show(this.$t("shared.missingFields"), this.$t("app.contracts.errors.linkRequired"));
      return;
    } else if (!this.name) {
      this.$refs.errorModal.show(this.$t("shared.missingFields"), this.$t("app.contracts.errors.nameRequired"));
      return;
    } else if (!this.description) {
      this.$refs.errorModal.show(this.$t("app.contracts.errors.descriptionRequired"));
      return;
    } else if (!this.contractFile) {
      this.$refs.errorModal.show(this.$t("shared.missingFields"), this.$t("app.contracts.errors.fileRequired"));
    } else if (!this.members || this.members.filter((f) => f.role === ContractMemberRole.SIGNATORY).length < 2) {
      this.$refs.errorModal.show(this.$t("shared.missingFields"), this.$t("app.contracts.errors.atLeastNSignatories"));
      return;
    } else {
      this.$refs.confirmCreate.show(this.$t("app.contracts.actions.create"), this.$t("shared.create"), this.$t("shared.cancel"), this.$t("app.contracts.actions.createDescription"));
    }
  }
  saveContract() {
    this.loading = true;
    services.contracts
      .create({
        linkId: this.linkId,
        // clientWorkspaceId: this.clientWorkspaceId,
        name: this.name,
        description: this.description,
        file: this.contractFile,
        members: this.members,
        employees: this.employees,
      })
      .then((response: ContractDto) => {
        this.$router.push("/app/contract/" + response.id);
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  droppedContractFile(files: FileBase64[]) {
    if (files.length > 0) {
      const mb = files[0].file.size / 1048576;
      if (mb >= 20) {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t("app.contracts.errors.maxFileReached"));
      } else {
        this.contractFile = files[0].base64;
      }
    }
  }
  selectedLink(id: string, link: LinkDto) {
    this.linkId = id;
    this.link = link;
    this.$nextTick(() => {
      this.$refs.inputName.focus();
      this.$refs.inputName.select();
    });
  }
  selectedEmployees(items: EmployeeDto[]) {
    this.employees = items;
  }
  selectedContractMembers(items: AddContractMemberDto[]) {
    this.members = items;
  }
  get imProvider() {
    return store.state.tenant.currentWorkspace?.id === this.link?.providerWorkspaceId;
  }
  get features(): TenantFeaturesDto {
    return store.state.app.features;
  }
  get usage(): AppUsageSummaryDto {
    return store.state.app.usage;
  }
  get maxContractsReached() {
    if (!this.features) {
      return true;
    } else {
      return this.features.monthlyContracts > 0 && this.usage.contracts >= this.features.monthlyContracts;
    }
  }
}
