













































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { EmployeeDto } from "@/application/dtos/app/employees/EmployeeDto";
import services from "@/services";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import IconWorkers from "@/assets/icons/IconWorkers.vue";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    EmptyState,
    ErrorModal,
    IconWorkers,
    Loading,
  },
})
export default class SelectEmployees extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
  };
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  searchInput = "";
  items: EmployeeDto[] = [];
  loading = false;
  selected: string[] = [];
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(selected: string[]) {
    this.selected = selected;
    this.showing = true;

    this.reload();
  }
  reload() {
    this.loading = true;
    services.employees
      .getAll()
      .then((response) => {
        this.items = response;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  toggle(item: EmployeeDto) {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter((f) => f !== item.id);
    } else {
      this.selected.push(item.id);
    }
  }
  isSelected(item: EmployeeDto) {
    return this.selected.find((f) => f === item.id);
  }
  accept() {
    const selected: EmployeeDto[] = [];
    this.items.forEach((element) => {
      if (this.isSelected(element)) {
        selected.push(element);
      }
    });
    this.$emit("selected", selected);
    this.close();
  }
  get filteredItems(): EmployeeDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter((f) =>
      f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
      f.firstName?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) ||
      f.lastName?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) ||
      f.email?.toString().toUpperCase().includes(this.searchInput.toUpperCase()));

    return items;
  }
}
