






































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import i18n from "@/locale/i18n";
import { EmployeeDto } from "@/application/dtos/app/employees/EmployeeDto";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import DateUtils from "@/utils/shared/DateUtils";
import ButtonTertiary from "@/components/ui/buttons/ButtonTertiary.vue";

@Component({
  components: {
    EmptyState,
    ButtonTertiary
  },
})
export default class EmployeesListAndTable extends Vue {
  @Prop({ default: true }) canEdit!: boolean;
  @Prop({}) items!: EmployeeDto[];
  sortByColumn = "";
  sortDirection = -1;
  headers: any[] = [
    {
      name: "firstName",
      title: i18n.t("models.employee.firstName"),
    },
    {
      name: "lastName",
      title: i18n.t("models.employee.lastName"),
    },
  ];
  mounted() {
    if (this.canEdit) {
      this.headers.push({
        name: "email",
        title: i18n.t("models.employee.email"),
      });
      this.headers.push({
        title: i18n.t("shared.actions"),
      });
    }
  }
  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  dateMonthName(value: Date | undefined) {
    return DateUtils.dateMonthName(value);
  }
  get sortedItems(): EmployeeDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
