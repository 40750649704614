














































































































import Vue from "vue";
import Component from "vue-class-component";
import i18n from '@/locale/i18n';
import services from "@/services";
import { EmailTemplateDto } from "@/application/dtos/core/email/EmailTemplateDto";
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";
import Loading from "@/components/ui/loaders/Loading.vue";
import EmptyState from "../../components/ui/emptyState/EmptyState.vue";

@Component({
  metaInfo: {
    title: i18n.t("admin.emails.title").toString(),
  },
  components: {
    ButtonSecondary,
    Loading,
    EmptyState
  }
})
export default class Navigation extends Vue {
  items: EmailTemplateDto[] = []
  loading = false;
  canCreateEmailTemplates = false;
  headers = [

    {
      title: i18n.t("admin.emails.created"),
    },
    {
      title: i18n.t("admin.emails.name"),
    },
    {
      title: i18n.t("admin.emails.alias"),
    },
    {
      title: i18n.t("admin.emails.subject"),
    },
    {
      title: i18n.t("shared.actions"),
    },
  ];

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    this.canCreateEmailTemplates = false;
    services.setup.getPostmarkTemplates().then((response) => {
      this.items = response;
      if (this.items.filter(f => !f.created).length > 0) {
        this.canCreateEmailTemplates = true;
      }
    })
      .finally(() => {
        this.loading = false;
      })
  }
  createAll() {
    this.loading = true;
    services.setup.createPostmarkTemplates().then(() => {
      this.reload();
    }).catch((error) => {
      this.loading = false;
      console.error(error)
    });
  }
  templateUrl(item: EmailTemplateDto) {
    return `https://account.postmarkapp.com/servers/${item.associatedServerId}/templates/${item.templateId}/edit`
  }
}
