

































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import SelectWorkspaces from "@/components/core/workspaces/SelectWorkspaces.vue";
import tinyEventBus from "@/plugins/tinyEventBus";
import store from "@/store";
import WarningBanner from "@/components/ui/banners/WarningBanner.vue";
import Loading from "@/components/ui/loaders/Loading.vue";
import i18n from "@/locale/i18n";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    SelectWorkspaces,
    WarningBanner,
    Loading,
  },
})
export default class NewMember extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectWorkspaces: SelectWorkspaces;
    inputEmail: HTMLInputElement;
  };
  loading = false;
  email = "";
  firstName = "";
  lastName = "";
  phone = "";
  role: TenantUserRole = TenantUserRole.MEMBER;
  roleOptions = [
    {
      value: 0,
      name: i18n.t("settings.profile.roles.OWNER"),
      description: i18n.t("settings.profile.permissions.OWNER"),
    },
    {
      value: 1,
      name: i18n.t("settings.profile.roles.ADMIN"),
      description: i18n.t("settings.profile.permissions.ADMIN"),
    },
    {
      value: 2,
      name: i18n.t("settings.profile.roles.MEMBER"),
      description: i18n.t("settings.profile.permissions.MEMBER"),
    },
    {
      value: 3,
      name: i18n.t("settings.profile.roles.GUEST"),
      description: i18n.t("settings.profile.permissions.GUEST"),
    },
  ];
  workspaces: WorkspaceDto[] = [];
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.inputEmail) {
        this.$refs.inputEmail.focus();
        this.$refs.inputEmail.select();
      }
    });
  }
  close() {
    this.$router.push("/app/settings/members");
  }
  save() {
    if (this.workspaces.length === 0) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("account.tenant.members.errors.atLeastOneWorkspace"));
      return;
    }
    this.loading = true;
    services.tenantUserInvitations
      .inviteUser({
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        role: this.role,
        workspaces: this.workspaces,
      })
      .then((response) => {
        tinyEventBus().emitter.emit("user-added", response);
        this.$refs.successModal.show(this.$t("app.tenants.members.invitationSent"), this.$t("app.tenants.members.invitationDescription", [this.email, i18n.t("settings.profile.roles." + TenantUserRole[this.role])]));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selectUserWorkspaces() {
    this.$refs.selectWorkspaces.show([]);
  }
  selectedWorkspaces(items: WorkspaceDto[]) {
    this.workspaces = items;
  }
  changedRole(e) {
    const role: TenantUserRole = Number(e.target.value);
    this.role = role;
  }
  get currentWorkspacesDescription() {
    if (this.workspaces.length === 0) {
      return this.$t("app.workspaces.seletAtLeastOne");
    }
    return this.workspaces.map((f) => f.name).join(", ");
  }
  get maxUsers(): number {
    return store.state.app.features.maxUsers;
  }
  get maxUsersReached() {
    return this.maxUsers > 0 && store.state.tenant.members.length >= this.maxUsers;
  }
}
