












































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { SubscriptionProductDto } from "@/application/dtos/core/subscriptions/SubscriptionProductDto"
import services from "@/services";
import Loading from "@/components/ui/loaders/Loading.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";
import i18n from '@/locale/i18n';
import plans from "@/application/pricing/plans";

@Component({
  metaInfo: {
    title: i18n.t("admin.pricing.title").toString(),
  },
  components: {
    Loading,
    ErrorModal,
    ButtonSecondary
  },
})
export default class Pricing extends Vue {
  $refs!: {
    errorModal: ErrorModal;
  };
  loading = false;
  items = [] as SubscriptionProductDto[];
  fromServer = true;
  mounted() {
    this.reload();
  }
  reload() {
    this.items = [];
    this.loading = true;
    services.subscriptionProducts
      .getProducts()
      .then((response: SubscriptionProductDto[]) => {
        this.items = [];
        if (response.length === 0) {
          this.items = plans;
        } else {
          response?.forEach((product) => {
            this.items.push(product);
          });
        }
        const notInDatabase = this.items.filter(f => f.id === undefined || f.id === '');
        if (notInDatabase.length === 0) {
          this.fromServer = true;
        }
        else if (notInDatabase.length === this.items.length) {
          this.fromServer = false;
        }
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  generateFromFiles() {
    this.loading = true;
    this.createAllPlans().finally(() => {
      this.reload();
    });
  }
  async createAllPlans() {
    const allAsyncResults: any[] = [];

    for (const product of this.items) {
      const asyncResult = await services.subscriptionProducts.createProduct(product).catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
      allAsyncResults.push(asyncResult);
    }

    return allAsyncResults;
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return x?.tier > y?.tier ? 1 : -1;
    });
  }
}
