




















































import Component from "vue-class-component";
import Vue from "vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import LoadingButton from "@/components/ui/buttons/LoadingButton.vue";
// @ts-ignore
import { Card, createToken } from "vue-stripe-elements-plus";
import i18n from "@/locale/i18n";
import services from "@/services";
import TenantUtils from '@/utils/store/TenantUtils';
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";

@Component({
  components: {
    LoadingButton,
    SuccessModal,
    ErrorModal,
    Card,
    ButtonSecondary
  },
})
export default class MySubscriptionPaymentDetails extends Vue {
  $refs!: {
    successModal: SuccessModal;
    errorModal: ErrorModal;
    loadingButton: LoadingButton;
  };
  editing = false;
  stripeKey!: string;
  stripeOptions!: any;
  complete = false;
  created() {
    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: i18n.locale,
      },
    };
  }
  updatePaymentDetails() {
    this.$refs.loadingButton.start();
    createToken()
      .then((data) => {
        if (data.error) {
          this.$refs.errorModal.show(data.error.message);
          if (this.$refs.loadingButton) {
            this.$refs.loadingButton.stop();
          }
        } else {
          if (data.token.id && data.token.id !== "") {
            this.updateCardToken(data.token.id);
          }
        }
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
        this.editing = false;
      });
  }
  updateCardToken(token: string) {
    services.subscriptionManager
      .updateCardToken(token)
      .then(() => {
        services.subscriptionManager.getCurrentSubscription();
        this.$refs.successModal.show(this.$t("shared.updated"), this.$t("settings.tenant.payment.updated"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
        this.editing = false;
      });
  }
  get subscriptionCard() {
    return TenantUtils.defaultSubscriptionCard();
  }
  get subscriptionCardLast4() {
    return this.subscriptionCard?.lastFourDigits;
  }
  get subscriptionCardExpDesc() {
    return ("0" + this.subscriptionCard?.expiryMonth).slice(-2) + " / " + this.subscriptionCard?.expiryYear;
  }
}
