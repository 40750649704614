var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SlideOver',{attrs:{"title":_vm.$t('shared.upload') + ' ' + _vm.title},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('PictureInput',{ref:"pictureInput",attrs:{"width":"600","height":"600","margin":"16","accept":"image/jpeg, image/png, image/jpg, image/ico","size":"10","button-class":"btn","customStrings":{
          tap: _vm.$t('app.shared.pictureInput.tap'),
          change: _vm.$t('app.shared.pictureInput.tap'),
          remove: _vm.$t('app.shared.pictureInput.remove'),
          select: _vm.$t('app.shared.pictureInput.select'),
          selected: _vm.$t('app.shared.pictureInput.selected'),
          fileSize: _vm.$t('app.shared.pictureInput.fileSize'),
          fileType: _vm.$t('app.shared.pictureInput.fileType'),
          aspect: _vm.$t('app.shared.pictureInput.aspect'),
          upload: _vm.$t('app.shared.pictureInput.upload'),
          drag: _vm.$t('app.shared.pictureInput.drag')
        }},on:{"change":_vm.onChange}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }