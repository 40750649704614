




































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import UploadImage from "@/components/ui/uploaders/UploadImage.vue";
import { UserLoginType } from "@/application/enums/core/users/UserLoginType";
import store from "@/store";
import i18n from "@/locale/i18n";
import { UserType } from "@/application/enums/core/users/UserType";
import services from "@/services";
import { UserUpdatePasswordRequest } from '@/application/contracts/core/users/UserUpdatePasswordRequest';
import { UserDto } from '@/application/dtos/core/users/UserDto';
import supportedLocales from '@/locale/supportedLocales';
import { UserUpdateRequest } from '@/application/contracts/core/users/UserUpdateRequest';
import { UserUpdateAvatarRequest } from '@/application/contracts/core/users/UserUpdateAvatarRequest';
import ButtonPrimary from "@/components/ui/buttons/ButtonPrimary.vue";
import ButtonTertiary from "@/components/ui/buttons/ButtonTertiary.vue";

@Component({
  metaInfo: {
    title: i18n.t("settings.profile.profileTitle").toString()
  },
  components: {
    SuccessModal,
    ErrorModal,
    UploadImage,
    ConfirmModal,
    ButtonPrimary,
    ButtonTertiary
  },
})
export default class Profile extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmModal: ConfirmModal;
  };
  profile = {} as UserDto;
  updatePasswordRequest = {} as UserUpdatePasswordRequest;
  selectedLocale = "";
  locales = supportedLocales;
  showUploadImage = false;
  uploadingImage = false;
  created() {
    this.profile.id = store.state.account.user?.id ?? "";
    this.profile.email = store.state.account.user?.email ?? "";
    this.profile.firstName = store.state.account.user?.firstName ?? "";
    this.profile.lastName = store.state.account.user?.lastName ?? "";
    this.profile.phone = store.state.account.user?.phone ?? "";
    this.profile.loginType = store.state.account.user?.loginType ?? UserLoginType.PASSWORD;
    this.selectedLocale = i18n.locale;
  }
  canChangePassword() {
    return this.profile.loginType === UserLoginType.PASSWORD;
  }
  changedLocale(locale: string) {
    services.users
      .updateLocale({ locale })
      .then(() => {
        this.$router.go(0);
      })
      .finally(() => {
        localStorage.setItem("locale", locale);
        i18n.locale = locale;
      });
  }
  updateProfile() {
    const updateRequest: UserUpdateRequest = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      phone: this.profile.phone,
    };
    services.users
      .update(this.profile.id, updateRequest)
      .then(() => {
        this.$refs.successModal.show(this.$t("shared.updated"), this.$t("settings.profile.profileUpdated"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  logout() {
    store.commit("auth/logout");
  }
  updatePassword() {
    services.users
      .updatePassword(this.updatePasswordRequest)
      .then(() => {
        this.$refs.successModal.show(this.$t("shared.updated"), this.$t("settings.profile.passwordUpdated"));
        this.updatePasswordRequest.passwordCurrent = "";
        this.updatePasswordRequest.passwordNew = "";
        this.updatePasswordRequest.passwordConfirm = "";
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  deleteAccount() {
    if (store.state.account.user?.type === UserType.Admin) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("settings.profile.errors.cannotDeleteAdmin"));
    } else {
      this.$refs.confirmModal.show(this.$t("shared.warning"), this.$t("shared.delete"), this.$t("shared.back"), this.$t("settings.danger.confirmDelete"));
    }
  }
  confirmDelete() {
    services.users
      .deleteMe()
      .then(() => {
        // ignore
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  loadedImage(image) {
    const updateAvatar: UserUpdateAvatarRequest = {
      avatar: image,
    };
    this.uploadingImage = true;
    services.users
      .updateAvatar(updateAvatar)
      .then(() => {
        this.showUploadImage = false;
      })
      .catch((error) => {
        console.error("Error: " + JSON.stringify(error));
      })
      .finally(() => {
        this.uploadingImage = false;
      });
  }
  get avatar() {
    return store.state.account.user?.avatar ?? "";
  }
  get email() {
    return store.state.account.user?.email ?? "";
  }
}
