





























































































































import Vue from "vue";
import store from "@/store";
import services from "@/services";
import { TenantProductDto } from "@/application/dtos/core/tenants/TenantProductDto";
import { SubscriptionGetCurrentResponse } from "@/application/contracts/core/subscriptions/SubscriptionGetCurrentResponse";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import { TenantUserDto } from "@/application/dtos/core/tenants/TenantUserDto";
import tinyEventBus from "@/plugins/tinyEventBus";
import { Prop, Component } from "vue-property-decorator";
import { AppUsageSummaryDto } from "@/application/dtos/app/usage/AppUsageSummaryDto";

@Component({})
export default class MySubscriptionProducts extends Vue {
  @Prop({ default: false }) withCurrentPlan!: boolean;
  @Prop({ default: "grid-cols-2 sm:grid-cols-2 xl:grid-cols-4" })
  cols!: boolean;
  loading = false;
  subscription: SubscriptionGetCurrentResponse | null = null;
  workspaces: WorkspaceDto[] = [];
  users: TenantUserDto[] = [];
  mounted() {
    tinyEventBus().emitter.on("updated-plan", () => {
      this.reload();
    });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("updated-plan");
  }
  reload() {
    this.loading = true;

    const promises: any[] = [];

    const loadDashboard = services.subscriptionManager.getCurrentSubscription().then((response) => {
      this.subscription = response;
    });
    const loadWorkspaces = services.workspaces.getAllWorkspaces(false).then((response) => {
      this.workspaces = response;
    });
    const loadUsers = services.tenantUsers.getAll().then((response) => {
      this.users = response;
    });
    promises.push(loadDashboard);
    promises.push(loadWorkspaces);
    // promises.push(loadFeatures);
    promises.push(loadUsers);

    this.loading = true;
    Promise.all(promises).finally(() => {
      this.loading = false;
    });
  }
  billableStatus(max: number): number {
    if (this.loading) {
      return 2;
    }
    if (!this.currentProduct) {
      return 0;
    }
    if (max === 0) {
      return 1;
    }
    if (max > 0) {
      return 2;
    }
    return 0;
  }
  get links(): number {
    return store.state.app.usage.providers + store.state.app.usage.clients;
  }
  get currentProduct(): TenantProductDto | undefined {
    if (this.subscription?.myProducts && this.subscription?.myProducts.length > 0) {
      return this.subscription?.myProducts[0];
    }
    return undefined;
  }
  get maxLinksRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    const links = this.usage.providers + this.usage.clients;
    const remaining = this.currentProduct.maxLinks - links;
    return remaining;
  }
  get maxDocumentsRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    return this.currentProduct.monthlyContracts - this.usage.contracts;
  }
  get maxWorkspacesRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    return this.currentProduct.maxWorkspaces - this.workspaces.length;
  }
  get maxUsersRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    return this.currentProduct.maxUsers - this.users.length;
  }
  get usage(): AppUsageSummaryDto {
    return store.state.app.usage;
  }
}
