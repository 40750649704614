














































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { TenantDto } from "@/application/dtos/core/tenants/TenantDto";
import { TenantProductDto } from "@/application/dtos/core/tenants/TenantProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/core/subscriptions/SubscriptionPriceDto";
import { SubscriptionBillingPeriod } from "@/application/enums/core/subscriptions/SubscriptionBillingPeriod";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import i18n from '@/locale/i18n';
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Loading,
  },
})
export default class TenantSubscription extends Vue {
  @Prop({}) id!: string;
  loading = false;
  openOptions = false;

  item: TenantDto = {} as TenantDto;
  products: TenantProductDto[] = [];

  headers = [
    {
      title: i18n.t("app.tenants.subscription.plan"),
    },
    {
      title: i18n.t("app.tenants.subscription.price"),
    },
    {
      title: i18n.t("app.tenants.subscription.starts"),
    },
    {
      title: i18n.t("app.tenants.subscription.ends"),
    },
    {
      title: i18n.t("app.tenants.subscription.isTrial"),
    },
    {
      title: i18n.t("app.tenants.subscription.status"),
    },
    {
      title: i18n.t("app.tenants.subscription.workspaces"),
    },
    {
      title: i18n.t("app.tenants.subscription.members"),
    },
    {
      title: i18n.t("app.tenants.subscription.links"),
    },
    {
      title: i18n.t("models.contract.plural"),
    },
    {
      title: i18n.t("app.tenants.subscription.storage"),
    },
  ];
  mounted() {
    this.reload();
  }
  reload() {
    this.closeOptions();
    const promises: any[] = [
      services.tenants.get(this.id).then((response) => {
        this.item = response;
      }),
      services.tenants.adminGetProducts(this.id).then((response) => {
        this.products = response;
      }),
    ];

    this.loading = true;
    Promise.all(promises).finally(() => {
      this.loading = false;
    });
  }
  closeOptions() {
    this.openOptions = false;
  }
  priceBillingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("pricing.once").toString();
    } else {
      return "/" + this.$t("pricing." + SubscriptionBillingPeriod[price.billingPeriod] + "Short");
    }
  }
  dateAgo(value: Date) {
    return DateUtils.dateAgo(value);
  }
  dateYMD(value: Date | undefined) {
    return DateUtils.dateYMD(value);
  }
}
