











































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import services from "@/services";
import { ContractDto } from "@/application/dtos/app/contracts/ContractDto";
import IconContractClock from "@/assets/icons/IconContractClock.vue";
import IconContractCheck from "@/assets/icons/IconContractCheck.vue";
import IconContractArchived from "@/assets/icons/IconContractArchived.vue";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import store from "@/store";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import IconSign from "@/assets/icons/IconSign.vue";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import { WorkspaceDto } from '@/application/dtos/core/workspaces/WorkspaceDto';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    IconContractClock,
    IconContractCheck,
    IconContractArchived,
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    IconSign,
    Loading,
  },
})
export default class LinkProfile extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    successModalDeleted: SuccessModal;
    errorModal: ErrorModal;
  };
  @Prop() id!: string;
  loading = false;
  loadingContracts = false;
  openOptions = false;

  link: LinkDto = {} as LinkDto;
  contracts: ContractDto[] = [];

  mounted() {
    this.reload();
    this.reloadContracts();
  }
  reload() {
    this.closeOptions();
    this.loading = true;
    services.links
      .get(this.id)
      .then((response) => {
        this.link = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  reloadContracts() {
    this.loadingContracts = true;
    services.contracts
      .getAllByLink(this.link.id)
      .then((response) => {
        this.contracts = response;
      })
      .finally(() => {
        this.loadingContracts = false;
      });
  }
  deleteLink() {
    this.closeOptions();
    this.$refs.confirmDelete.show(this.$t("shared.confirmDelete"), this.$t("shared.delete"), this.$t("shared.cancel"), this.$t("shared.warningCannotUndo"));
  }
  confirmedDelete() {
    this.loading = true;
    services.links
      .delete(this.id)
      .then(() => {
        services.tenants.getCurrentUsage(AppUsageType.ALL);
        this.$refs.successModalDeleted.show(this.$t("shared.deleted"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  successModalDeletedClosed() {
    if (this.link.providerWorkspaceId === store.state.tenant.currentWorkspace?.id) {
      this.$router.push("/app/links/clients");
    } else {
      this.$router.push("/app/links/providers");
    }
  }
  closeOptions() {
    this.openOptions = false;
  }
  toggleOptions() {
    this.openOptions = !this.openOptions;
  }
  dateMonthDayYear(value: Date | undefined) {
    return DateUtils.dateMonthDayYear(value);
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
  whoAmI(item: LinkDto) {
    const currentWorkspaceId = store.state.tenant.currentWorkspace?.id ?? "";
    if (currentWorkspaceId === item.providerWorkspaceId) {
      return 0;
    }
    return 1;
  }
  get getWorkspace(): WorkspaceDto | undefined {
    if (this.whoAmI(this.link) === 0) {
      return this.link.clientWorkspace;
    } else {
      return this.link.providerWorkspace;
    }
  }
  get isOwnerOrAdmin(): boolean {
    return this.currentRole === TenantUserRole.OWNER || this.currentRole === TenantUserRole.ADMIN;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
}
