












































































































































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { EmployeeDto } from "@/application/dtos/app/employees/EmployeeDto";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import { Prop } from 'vue-property-decorator';
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";

@Component({
  components: {
    ConfirmModal,
    ErrorModal,
    SuccessModal,
    Loading,
    ButtonSecondary
  },
})
export default class EmployeeProfile extends Vue {
  $refs!: {
    confirmSave: ConfirmModal;
    confirmDeleteEmployee: ConfirmModal;
    errorModal: ErrorModal;
    successModal: SuccessModal;
    successModalDeleted: SuccessModal;
    firstNameInput: HTMLInputElement;
  };
  @Prop({}) id!: string;
  loading = false;
  item: EmployeeDto = {} as EmployeeDto;
  firstName = "";
  lastName = "";
  email = "";

  editing = false;
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.employees
      .get(this.id)
      .then((response) => {
        this.item = response;
        this.loadProfile();
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadProfile() {
    this.firstName = this.item.firstName;
    this.lastName = this.item.lastName;
    this.email = this.item.email ?? "";
  }
  edit() {
    this.editing = true;
    this.$nextTick(() => {
      this.$refs.firstNameInput.focus();
      this.$refs.firstNameInput.select();
    });
  }
  save() {
    if (this.thereAreNoChanges) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("shared.noChanges"));
    } else {
      this.$refs.confirmSave.show(this.$t("shared.saveChanges"), this.$t("shared.yes"), this.$t("shared.cancel"));
    }
  }
  deleteEmployee() {
    this.$refs.confirmDeleteEmployee.show(this.$t("shared.confirmDelete"), this.$t("shared.yes"), this.$t("shared.cancel"), this.$t("shared.warningCannotUndo"));
  }
  successModalDeletedClosed() {
    this.$router.push("/app/employees");
  }
  confirmedDeleteEmployee() {
    this.loading = true;
    services.employees
      .delete(this.id)
      .then(() => {
        this.$refs.successModalDeleted.show(this.$t("shared.deleted"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  saveConfirm() {
    this.loading = true;
    services.employees
      .update(this.id, {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      })
      .then((response) => {
        this.$refs.successModal.show(this.$t("shared.updated"));
        this.item = response;
        this.loadProfile();
        this.editing = false;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  cancel() {
    this.loadProfile();
    this.editing = false;
  }
  dateMonthDayYear(value: Date | undefined) {
    return DateUtils.dateMonthDayYear(value);
  }
  get thereAreNoChanges() {
    if (this.item) {
      return this.firstName === this.item.firstName && this.lastName === this.item.lastName && this.email === this.item.email;
    }
    return true;
  }
}
