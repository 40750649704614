




























































































































































import Vue from "vue";
import Component from "vue-class-component";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import services from "@/services";
import store from "@/store";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import tinyEventBus from "@/plugins/tinyEventBus";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    ConfirmModal,
    EmptyState,
    ErrorModal,
    Loading,
  },
})
export default class PendingLinksList extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    modalReject: ConfirmModal;
    modalAccept: ConfirmModal;
  };
  items: LinkDto[] = [];
  loading = false;
  error = "";

  mounted() {
    this.reload();
    tinyEventBus().emitter.on("reload-links", () => {
      this.reload();
    });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("reload-links");
  }
  reload() {
    this.items = [];
    this.loading = true;
    services.links
      .getAllPending()
      .then((response) => {
        this.items = response;
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  reject(item: LinkDto) {
    const whoAmIName = this.whoAmI(item) === 0 ? this.$t("models.provider.object") : this.$t("models.client.object");
    this.$refs.modalReject.value = item;
    this.$refs.modalReject.show(this.$t("app.links.invitation.confirmReject"), this.$t("shared.reject"), this.$t("shared.back"), this.$t("app.links.invitation.rejectWarning", [whoAmIName, this.inviterWorkspace(item).name]));
  }
  accept(item: LinkDto) {
    const whoAmIName = this.whoAmI(item) === 0 ? this.$t("models.provider.object") : this.$t("models.client.object");
    this.$refs.modalAccept.value = item;
    this.$refs.modalAccept.show(this.$t("app.links.invitation.confirmAccept", [whoAmIName]), this.$t("shared.accept"), this.$t("shared.back"), this.$t("app.links.invitation.acceptWarning", [this.inviterWorkspace(item).name]));
  }
  accepted(item: LinkDto) {
    this.loading = true;
    services.links
      .acceptOrReject(item.id, {
        accepted: true,
      })
      .then(() => {
        item.status = 1;
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  rejected(item: LinkDto) {
    this.loading = true;
    services.links
      .acceptOrReject(item.id, {
        accepted: false,
      })
      .then(() => {
        item.status = 2;
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  whoAmI(item: LinkDto) {
    const currentWorkspaceId = store.state.tenant.currentWorkspace?.id ?? "";
    if (currentWorkspaceId === item.providerWorkspaceId) {
      return 0;
    }
    return 1;
  }
  inviterWorkspace(item: LinkDto): WorkspaceDto {
    if (item.createdByWorkspaceId === item.providerWorkspaceId) {
      return item.providerWorkspace;
    }
    return item.clientWorkspace;
  }
  dateAgo(value: Date) {
    return DateUtils.dateAgo(value);
  }
  get currentWorkspaceId() {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
}
