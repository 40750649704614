













import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/ui/breadcrumbs/Breadcrumb.vue";
import ContractDetails from "@/components/app/contracts/ContractDetails.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("models.contract.object").toString()
  },
  components: {
    Breadcrumb,
    ContractDetails,
  },
})
export default class Contracts extends Vue {
  id = "";
  selected = 0;
  created() {
    this.id = this.$route.params.id;
  }
  selectedTab(tab) {
    this.selected = tab;
  }
}
