












































import Vue from "vue";
import Component from "vue-class-component";
import tinyEventBus from "@/plugins/tinyEventBus";
import Tabs, { TabItem } from "@/components/ui/tabs/Tabs.vue"
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("app.links.my").toString(),
  },
  components: {
    Tabs,
  },
})
export default class Links extends Vue {
  tabs: TabItem[] = [];
  mounted() {
    this.tabs = [
      {
        name: this.$t("shared.all"),
        routePath: "/app/links/all"
      },
      {
        name: this.$t("models.provider.plural"),
        routePath: "/app/links/providers"
      },
      {
        name: this.$t("models.client.plural"),
        routePath: "/app/links/clients"
      },
      {
        name: this.$t("app.links.pending.title"),
        routePath: "/app/links/pending"
      },
    ];
  }
  newLink() {
    tinyEventBus().emitter.emit("new-link");
  }
}
