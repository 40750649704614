




















































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ContractDto } from "@/application/dtos/app/contracts/ContractDto";
import IconContractClock from "@/assets/icons/IconContractClock.vue";
import IconContractCheck from "@/assets/icons/IconContractCheck.vue";
import IconContractArchived from "@/assets/icons/IconContractArchived.vue";
import i18n from "@/locale/i18n";
import store from "@/store";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {
    IconContractClock,
    IconContractCheck,
    IconContractArchived,
    EmptyState,
  },
})
export default class ContractsListAndTable extends Vue {
  @Prop({}) items!: ContractDto[];
  sortByColumn = "createdAt";
  sortDirection = 1;
  headers = [
    {
      name: "name",
      title: i18n.t("models.contract.name"),
    },
    {
      title: i18n.t("models.provider.object"),
    },
    {
      title: i18n.t("models.client.object"),
      sortable: true,
    },
    {
      name: "status",
      title: i18n.t("models.contract.status"),
    },
    {
      name: "createdAt",
      title: i18n.t("shared.created"),
    }
  ];

  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceNameIs(name) {
    return store.state.tenant.currentWorkspace?.name === name;
  }
  dateMonthName(value: Date | undefined) {
    return DateUtils.dateMonthName(value);
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
  get sortedItems(): ContractDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
