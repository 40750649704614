






















































import Component from "vue-class-component";
import Vue from "vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import UploadImage from "@/components/ui/uploaders/UploadImage.vue";
import { TenantUpdateImageRequest } from "@/application/contracts/core/tenants/TenantUpdateImageRequest";
import { TenantDto } from "@/application/dtos/core/tenants/TenantDto";
import store from "@/store";
import services from "@/services";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("app.navbar.tenant").toString()
  },
  components: {
    SuccessModal,
    ErrorModal,
    UploadImage,
  },
})
export default class Tenant extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
  };
  imageType = "";
  imageCurrent = "";
  tenant = {} as TenantDto;
  showUploadImage = false;
  uploadingImage = false;
  created() {
    this.getCurrentTenantInForm();
  }
  getCurrentTenantInForm() {
    this.tenant.name = store.state.tenant.current?.name ?? "";
    this.tenant.subdomain = store.state.tenant.current?.subdomain ?? "";
    this.tenant.domain = store.state.tenant.current?.domain ?? "";
  }
  updateTenant() {
    services.tenants
      .update(this.tenant)
      .then(() => {
        services.tenants.getAll().then(() => {
          this.getCurrentTenantInForm();
        });

        this.$refs.successModal.show(this.$t("shared.updated"), this.$t("settings.tenant.updated"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  loadedImage(image) {
    const payload: TenantUpdateImageRequest = {
      type: this.imageType,
      logo: this.imageType === "logo" ? image : "",
      icon: this.imageType === "icon" ? image : "",
      logoDarkmode: this.imageType === "logoDarkmode" ? image : "",
    };
    payload.type = this.imageType;
    this.uploadingImage = true;
    services.tenants
      .updateImage(payload)
      .then(() => {
        store.commit("tenant/setCurrentImage", {
          imageType: this.imageType,
          image,
        });
        this.showUploadImage = false;
      })
      .catch((error) => {
        console.error("Error: " + error);
      })
      .finally(() => {
        this.uploadingImage = false;
      });
  }
}
