


















import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/ui/breadcrumbs/Breadcrumb.vue";
import Tabs, { TabItem } from "@/components/ui/tabs/Tabs.vue"
import TenantProfile from "@/components/core/tenants/TenantProfile.vue"
import TenantSubscription from "@/components/core/tenants/TenantSubscription.vue"
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("models.tenant.object").toString(),
  },
  components: {
    Breadcrumb,
    Tabs,
    TenantProfile,
    TenantSubscription
  },
})
export default class Tenant extends Vue {
  tabs: TabItem[] = [];
  selected = 0;
  id = "";
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.tabs = [
      {
        name: this.$t("admin.tenants.profile.title"),
      },
      {
        name: this.$t("admin.tenants.subscription.title"),
      },
    ];
  }
  selectedTab(idx) {
    this.selected = idx;
  }
}
