
































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    EmptyState,
    ErrorModal,
    Loading,
  },
})
export default class SelectWorkspaces extends Vue {
  @Prop({ default: false }) allowSearch!: boolean;
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
  };
  closeText!: TranslateResult | string;
  showing = false;
  searchInput = "";
  items: WorkspaceDto[] = [];
  loading = false;
  selected: string[] = [];
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(selected: string[]) {
    this.selected = selected;
    this.showing = true;

    // this.$nextTick(() => {
    //   window.addEventListener("keyup", this.keyup);
    // });

    this.reload();
  }
  reload() {
    this.loading = true;
    services.workspaces
      .getAllWorkspaces(false)
      .then((response) => {
        this.items = response;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  keyup(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  toggle(item: WorkspaceDto) {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter((f) => f !== item.id);
    } else {
      this.selected.push(item.id);
    }
  }
  isSelected(item: WorkspaceDto) {
    return this.selected.find((f) => f === item.id);
  }
  accept() {
    const selected: WorkspaceDto[] = [];
    this.items.forEach((element) => {
      if (this.isSelected(element)) {
        selected.push(element);
      }
    });
    this.$emit("selected", selected);
    this.close();
  }
  get filteredItems(): WorkspaceDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter((f) => f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) || f.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()));

    return items;
  }
}
