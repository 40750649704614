













































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import i18n from "@/locale/i18n";
import store from "@/store";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import ButtonTertiary from "@/components/ui/buttons/ButtonTertiary.vue";

@Component({
  components: {
    EmptyState,
    ButtonTertiary
  },
})
export default class AllLinksListAndTable extends Vue {
  @Prop({}) items!: LinkDto[];
  sortByColumn = "type";
  sortDirection = -1;
  headers = [
    {
      name: "name",
      title: i18n.t("models.workspace.object"),
    },
    {
      title: i18n.t("models.link.type"),
    },
    {
      title: i18n.t("models.workspace.businessMainActivity"),
    },
    {
      title: i18n.t("shared.actions"),
    },
  ];

  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  getWorkspace(item: LinkDto) {
    if (this.whoAmI(item) === 0) {
      return item.clientWorkspace;
    } else {
      return item.providerWorkspace;
    }
  }
  whoAmI(item: LinkDto) {
    const currentWorkspaceId = store.state.tenant.currentWorkspace?.id ?? "";
    if (currentWorkspaceId === item.providerWorkspaceId) {
      return 0;
    }
    return 1;
  }
  get sortedItems(): LinkDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
