








































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/core/workspaces/WorkspaceDto";
import store from "@/store";
import tinyEventBus from "@/plugins/tinyEventBus";
import services from "@/services";
import Loading from "@/components/ui/loaders/Loading.vue";
// import WarningBanner from "@/components/ui/banners/WarningBanner.vue";
import WorkspacesListAndTable from "@/components/core/workspaces/WorkspacesListAndTable.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("models.workspace.plural").toString()
  },
  components: {
    ErrorModal,
    Loading,
    // WarningBanner,
    WorkspacesListAndTable
  },
})
export default class WorkspacesList extends Vue {
  $refs!: {
    errorModal: ErrorModal;
  };
  loading = false;
  searchInput = "";
  mounted() {
    tinyEventBus().emitter.on("workspace-deleted", () => this.reload());
    tinyEventBus().emitter.on("workspace-added", () => this.reload());
    tinyEventBus().emitter.on("workspace-saved", () => this.reload());

    services.tenants.getFeatures();
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("workspace-deleted");
    tinyEventBus().emitter.off("workspace-added");
    tinyEventBus().emitter.off("workspace-saved");
  }
  async reload() {
    // this.items = [];
    this.loading = true;
    services.workspaces
      .getAllWorkspaces(true)
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get workspaces() {
    return store.state.tenant.workspaces;
  }
  get filteredItems(): WorkspaceDto[] {
    if (!this.workspaces) {
      return [];
    }
    return this.workspaces.filter((f) => f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) || f.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()));
  }
}
