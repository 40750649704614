








































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import tinyEventBus from "@/plugins/tinyEventBus";
import { ContractDto } from "@/application/dtos/app/contracts/ContractDto";
import services from "@/services";
import store from "@/store";
import UploadDocument from "@/components/ui/uploaders/UploadDocument.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import { ContractActivityType } from "@/application/enums/app/contracts/ContractActivityType";
import ContractMembers from "@/components/app/contracts/ContractMembers.vue";
import { ContractActivityDto } from "@/application/dtos/app/contracts/ContractActivityDto";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import Loading from "@/components/ui/loaders/Loading.vue";
import { Prop } from 'vue-property-decorator';
import DropdownWithClick from "@/components/ui/dropdowns/DropdownWithClick.vue";
import { ContractStatus } from "@/application/enums/app/contracts/ContractStatus";
import ContractEmployees from "./ContractEmployees.vue";
import ContractActivity from "./ContractActivity.vue";
import PdfViewer from "../../ui/pdf/PdfViewer.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    UploadDocument,
    ContractMembers,
    ContractEmployees,
    ContractActivity,
    Loading,
    PdfViewer,
    DropdownWithClick
  },
})
export default class ContractDetails extends Vue {
  $refs!: {
    buttonSave: HTMLButtonElement;
    confirmSendContract: ConfirmModal;
    confirmDelete: ConfirmModal;
    errorModal: ErrorModal;
    successModal: SuccessModal;
    inputName: HTMLInputElement;
  };
  @Prop({}) id!: string;
  name = "";
  status = ContractStatus.PENDING;
  description = "";
  contractPdf = "";

  editing = false;

  item?: ContractDto | null = null;
  error = "";
  loading = false;
  loadingPdf = false;
  currentPage = 1;
  pageCount = 0;
  mounted() {
    tinyEventBus().emitter.on("contract-reload", () => {
      this.reload();
    });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("contract-reload");
  }
  reload() {
    this.closeOptions();
    this.loading = true;
    this.item = undefined;
    services.contracts
      .getContract(this.id)
      .then((response) => {
        this.item = response;
        this.status = response.status;
        this.name = response.name;
        this.description = response.description;
        this.loadPdf();
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  nextPage(pages) {
    this.currentPage += pages;
  }
  removeFile() {
    if (this.item) {
      this.contractPdf = "";
    }
  }
  loadPdf() {
    this.downloadFile(false);
  }
  downloadFile(open: boolean) {
    this.closeOptions();
    this.loadingPdf = true;
    services.contracts
      .downloadFile(this.id)
      .then((response) => {
        if (open) {
          const downloadLink = document.createElement("a");
          const fileName = this.item?.name + ".pdf";
          downloadLink.href = response;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          this.contractPdf = response;
        }
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loadingPdf = false;
      });
  }
  downloadPdf() {
    this.closeOptions();
    if (this.item) {
      const downloadLink = document.createElement("a");
      const fileName = this.item?.name + ".pdf";
      downloadLink.href = this.contractPdf;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  toggleEdit() {
    this.closeOptions();
    this.editing = !this.editing;
    if (this.editing) {
      this.$nextTick(() => {
        this.$refs.inputName.focus();
        this.$refs.inputName.select();
      });
    }
  }
  deleteContract() {
    this.$refs.confirmDelete.show(this.$t("shared.confirmDelete"), this.$t("shared.delete"), this.$t("shared.cancel"), this.$t("shared.warningCannotUndo"));
  }
  yesDelete() {
    this.loading = true;
    services.contracts
      .delete(this.item?.id)
      .then(() => {
        this.$router.push("/app/contracts/all");
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
        this.loading = false;
      });
  }
  droppedContractFile(files: FileBase64[]) {
    if (this.item) {
      if (files.length > 0) {
        this.contractPdf = files[0].base64;
      }
    }
  }
  send() {
    this.closeOptions();
    this.$refs.confirmSendContract.show(
      this.$t("shared.send"),
      this.$t("shared.send"),
      this.$t("shared.cancel"),
      this.$t("shared.sendTo", [this.item?.members.map((f) => (f.user.firstName + " " + f.user.lastName).trim() + " (" + f.user.email + ")").join(", ")])
    );
  }
  yesSendContract() {
    if (!this.item) {
      return;
    }
    services.contracts
      .send(this.item.id, {
        emails: [],
      })
      .then(() => {
        this.$refs.successModal.show(this.$t("shared.sent"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  getActivityTitle(activity: ContractActivityDto) {
    switch (activity.type) {
      case ContractActivityType.CREATED:
        return this.$t("app.contracts.activity.types.CREATED");
    }
  }
  save() {
    if (!this.name) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("app.contracts.errors.nameRequired"));
    } else if (!this.description) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("app.contracts.errors.descriptionRequired"));
    } else if (!this.contractPdf) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("app.contracts.errors.fileRequired"));
    } else {
      this.loading = true;
      services.contracts
        .update(this.id, {
          name: this.name,
          status: this.status,
          description: this.description,
          file: this.contractPdf,
        })
        .then(() => {
          this.editing = false;
          this.$refs.successModal.show(this.$t("shared.updated"), this.$t("shared.saved"));
        })
        .catch((error) => {
          this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  closeOptions() {
    if (this.$refs["dropdownOptions"]) {
      this.$refs["dropdownOptions"].close();
    }
  }
  get clientFullName() {
    if (this.item && this.item.link?.clientWorkspace) {
      return `${this.item.link.clientWorkspace.name}`;
    }
    return "";
  }
  get providerFullName() {
    if (this.item && this.item.link?.providerWorkspace) {
      return `${this.item.link.providerWorkspace.name}`;
    }
    return "";
  }
  get canBeEdited() {
    return this.item?.members.filter((f) => f.role === 0 && f.signDate).length === 0;
  }
  get isOwnerOrAdmin(): boolean {
    return this.currentRole === TenantUserRole.OWNER || this.currentRole === TenantUserRole.ADMIN;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get canEdit() {
    if (this.isOwnerOrAdmin) {
      return true;
    }
    return this.item?.createdByUserId === store.state.account.user?.id || true;
  }
}
