



























































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TenantUserDto } from "@/application/dtos/core/tenants/TenantUserDto";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import { TenantUserStatus } from "@/application/enums/core/tenants/TenantUserStatus";
import i18n from "@/locale/i18n";
import EmptyState from '@/components/ui/emptyState/EmptyState.vue';

@Component({
  components: {
    EmptyState
  },
})
export default class MembersListAndTable extends Vue {
  @Prop({}) items!: TenantUserDto[];
  sortByColumn = "";
  sortDirection = -1;

  headers = [
    {
      name: "role",
      title: i18n.t("settings.profile.role"),
    },
    {
      name: "email",
      title: i18n.t("account.shared.email"),
    },
    {
      name: "firstName",
      title: i18n.t("settings.profile.name"),
    },
    {
      title: i18n.t("settings.profile.phone"),
    },
    {
      title: i18n.t("shared.status"),
    },
  ];

  getUserRole(item: TenantUserDto) {
    return this.$t("settings.profile.roles." + TenantUserRole[item.role]);
  }
  getUserStatus(item: TenantUserDto) {
    return this.$t("settings.profile.status." + TenantUserStatus[item.status]);
  }
  getUserRoleClass(item: TenantUserDto) {
    switch (item.role as TenantUserRole) {
      case TenantUserRole.OWNER:
        return "bg-blueGray-50 text-gray-800 border border-blueGray-300";
      case TenantUserRole.ADMIN:
        return "bg-rose-50 border border-rose-200";
      case TenantUserRole.MEMBER:
        return "bg-blue-50 border border-blue-200";
      case TenantUserRole.GUEST:
        return "bg-gray-50 border border-gray-200";
    }
  }
  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
}
