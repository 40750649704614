<template>
  <div class="relative">
    <Header />
    <section>
      <div class="mx-auto flex max-w-screen-xl px-4 py-16 lg:items-center">
        <div class="mx-auto max-w-7xl text-center md:max-w-6xl">
          <div class="mb-4">
          </div>
          <h1
            class="title-font mb-4 max-w-4xl text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl md:text-5xl md:font-extrabold">
            Build your SaaS with Vue2 and .NET
          </h1>
          <p class="mb-8 max-w-4xl leading-relaxed text-gray-600 dark:text-gray-400">
            Out-of-the-box features like ready-to-use components with Tailwind CSS, Front, Account and Admin SaaS pages, Fake API as sandbox environment, i18n to translate your app, persisted storage, Member management, Stripe subscriptions, Postmark Emails, Integration tests, Clean Architecture, and more.
          </p>

          <div class="mt-8 flex flex-wrap justify-center gap-4">
            <router-link to="/pricing" target="_blank"
              class="w-full sm:w-auto inline-flex justify-center rounded-md border-0 bg-theme-500 py-3 px-4 text-lg text-white shadow-sm hover:bg-theme-600 focus:outline-none">
              {{ $t("front.hero.start") }}
            </router-link>

            <router-link to="/contact"
              class="w-full sm:w-auto inline-flex justify-center rounded-md border-0 bg-gray-100 py-3 px-4 text-lg text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none">
              {{ $t("front.hero.contact") }}
            </router-link>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "@/components/front/Header.vue";

export default {
  components: {
    Header,
  }
}
</script>
