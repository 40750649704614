















import Vue from "vue";
import Component from "vue-class-component";
import AllComponentsList from "@/components/ui/AllComponentsList.vue";
import i18n from "@/locale/i18n";

@Component({
  metaInfo: {
    title: i18n.t("admin.components.title").toString(),
  },
  components: {
    AllComponentsList
  }
})
export default class Components extends Vue {
}
