








































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Tabs, { TabItem } from "@/components/ui/tabs/Tabs.vue"
import ContractsList from "@/components/app/contracts/ContractsList.vue";
import ButtonPrimary from "@/components/ui/buttons/ButtonPrimary.vue";
import { ContractStatusFilter } from '@/application/contracts/app/contracts/ContractStatusFilter';
import i18n from "@/locale/i18n";

@Component({
  metaInfo: {
    title: i18n.t("models.contract.plural").toString()
  },
  components: {
    Tabs,
    ContractsList,
    ButtonPrimary,
  },
})
export default class Contracts extends Vue {
  tabs: TabItem[] = [];
  filter: ContractStatusFilter = ContractStatusFilter.ALL;
  created() {
    this.changeContractsStatus();
  }
  mounted() {
    this.tabs = [
      {
        name: this.$t("shared.all"),
        routePath: "/app/contracts/all"
      },
      {
        name: this.$t("app.contracts.pending.title"),
        routePath: "/app/contracts/pending"
      },
      {
        name: this.$t("app.contracts.signed.title"),
        routePath: "/app/contracts/signed"
      },
      {
        name: this.$t("app.contracts.archived.title"),
        routePath: "/app/contracts/archived"
      },
    ];
    this.changeContractsStatus();
  }
  @Watch("$route.path")
  changeContractsStatus() {
    if (!this.$route.params.status) {
      this.$router.push({ path: "/app/contracts/pending" });
    } else {
      switch (this.$route.params.status) {
        case "pending":
          this.filter = ContractStatusFilter.PENDING;
          break;
        case "signed":
          this.filter = ContractStatusFilter.SIGNED;
          break;
        case "archived":
          this.filter = ContractStatusFilter.ARCHIVED;
          break;
        case "all":
          this.filter = ContractStatusFilter.ALL;
          break;
        default:
          this.$router.push({ path: "/app/contracts/pending" });
          break;
      }
    }
  }
}
