























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

export interface FeatureDto {
  name: string;
  description: string;
  img?: string;
  link?: {
    text: string;
    href: string;
    target?: string;
  };
}

@Component({
  components: {
    
  },
})
export default class FeaturesVariantsCard extends Vue {
  @Prop({}) feature!: FeatureDto;
}
