




























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/ui/breadcrumbs/Breadcrumb.vue";
import AddEmployees from "@/components/app/employees/AddEmployees.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("app.employees.new.multiple").toString()
  },
  components: {
    Breadcrumb,
    AddEmployees
  }
})
export default class NewEmployee extends Vue {
}
