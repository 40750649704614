





































































































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import SuccessModal from "@/components/ui/modals/SuccessModal.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import { TenantDto } from "@/application/dtos/core/tenants/TenantDto";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    Loading,
  },
})
export default class TenantProfile extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    successModalDeleted: SuccessModal;
    errorModal: ErrorModal;
  };
  @Prop({}) id!: string;
  loading = false;
  openOptions = false;

  item: TenantDto = {} as TenantDto;
  mounted() {
    this.reload();
  }
  reload() {
    this.closeOptions();
    this.loading = true;
    services.tenants
      .get(this.id)
      .then((response) => {
        this.item = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  confirmedDelete() {
    this.loading = true;
    services.tenants
      .adminDelete(this.id)
      .then(() => {
        this.$refs.successModalDeleted.show(this.$t("shared.deleted"), this.$t("app.tenants.actions.deleted"));
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  successModalDeletedClosed() {
    this.$router.push("/admin/tenants");
  }
  closeOptions() {
    this.openOptions = false;
  }
  dateMonthDayYear(value: Date | undefined) {
    return DateUtils.dateMonthDayYear(value);
  }
}
