






























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ContractEmployeeDto } from "@/application/dtos/app/contracts/ContractEmployeeDto";

@Component({
  components: {},
})
export default class ContractEmployees extends Vue {
  @Prop({}) items!: ContractEmployeeDto[];
  get sortedItems() {
    return this.items.sort((x, y) => {
      return x.employee?.firstName > y.employee?.firstName ? 1 : -1;
    });
  }
}
