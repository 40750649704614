























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FeaturesVariantCard from "./FeaturesVariantCard.vue";

export type FeaturesBlockDto = {
  style: string;
  headline: string;
  subheadline: string;
  topText?: string;
  items: FeatureDto[];
  grid?: { columns: string; gap: string};
};

export interface FeatureDto {
  name: string;
  description: string;
  img?: string;
  link?: {
    text: string;
    href: string;
    target?: string;
  };
}

@Component({
  components: {
    FeaturesVariantCard
  },
})
export default class FeaturesVariantsCards extends Vue {
  @Prop({}) item!: FeaturesBlockDto;
}
