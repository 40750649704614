


















































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import EmptyState from "@/components/ui/emptyState/EmptyState.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import IconSign from "@/assets/icons/IconSign.vue";
import { ContractMemberRole } from "@/application/enums/app/contracts/ContractMemberRole";
import { WorkspaceUserDto } from "@/application/dtos/core/workspaces/WorkspaceUserDto";
import { AddContractMemberDto } from "@/application/contracts/app/contracts/AddContractMemberDto";
import { LinkDto } from "@/application/dtos/core/links/LinkDto";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    EmptyState,
    ErrorModal,
    IconSign,
    Loading,
  },
})
export default class SelectContractMembers extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
  };
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  searchInput = "";
  items: WorkspaceUserDto[] = [];
  loading = false;
  selected: string[] = [];
  link: LinkDto | null = null;
  linkId = "";
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(link: LinkDto, linkId: string, selected: string[]) {
    this.link = link;
    this.linkId = linkId;
    this.selected = selected;
    this.showing = true;

    this.reload();
  }
  reload() {
    this.loading = true;
    services.links
      .getLinkUsers(this.linkId)
      .then((response) => {
        this.items = response;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  toggle(item: WorkspaceUserDto) {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter((f) => f !== item.userId);
    } else {
      this.selected.push(item.userId);
    }
  }
  isSelected(item: WorkspaceUserDto) {
    return this.selected.find((f) => f === item.userId);
  }
  accept() {
    const selected: AddContractMemberDto[] = [];
    this.items.forEach((element) => {
      if (this.isSelected(element)) {
        const contractMember: AddContractMemberDto = {
          userId: element.userId,
          role: ContractMemberRole.SIGNATORY,
          name: (element.user?.firstName + " " + element.user?.lastName).trim(),
          email: element.user?.email ?? "",
        };
        selected.push(contractMember);
      }
    });
    this.$emit("selected", selected);
    this.close();
  }
  getNoMembers() {
    if (this.link?.providerWorkspace && this.link.clientWorkspace) {
      return this.$t("app.tenants.members.noMembersFoundIn", [this.link?.clientWorkspace.name, this.link?.providerWorkspace.name]);
    }
    return this.$t("app.tenants.members.noMembers");
  }
  get filteredItems(): WorkspaceUserDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter((f) => f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) || f.workspace?.name?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.user?.firstName?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.user?.lastName?.toString().toUpperCase().includes(this.searchInput.toUpperCase()) || f.user?.email?.toString().toUpperCase().includes(this.searchInput.toUpperCase()));

    return items;
  }
}
