
















































































































import Vue from "vue";
import Component from "vue-class-component";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Modal from "@/components/ui/modals/Modal.vue";
import PlansRadioButtons from "@/components/ui/radios/PlansRadioButtons.vue"
// @ts-ignore
import { Card, createToken } from "vue-stripe-elements-plus";
import CurrencyToggle from "@/components/ui/toggles/CurrencyToggle.vue";
import { SubscriptionProductDto } from "@/application/dtos/core/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/core/subscriptions/SubscriptionPriceDto";
import { TenantCreateRequest } from "@/application/contracts/core/tenants/TenantCreateRequest";
import { SubscriptionBillingPeriod } from "@/application/enums/core/subscriptions/SubscriptionBillingPeriod";
import store from "@/store";
import services from "@/services";
import ButtonPrimary from "@/components/ui/buttons/ButtonPrimary.vue";
import ButtonSecondary from "@/components/ui/buttons/ButtonSecondary.vue";

@Component({
  components: {
    ErrorModal,
    Modal,
    ConfirmModal,
    PlansRadioButtons,
    Card,
    CurrencyToggle,
    ButtonPrimary,
    ButtonSecondary
  },
})
export default class TenantNew extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    cardModal: Modal;
    confirmModal: ConfirmModal;
  };
  name = "";
  stripeKey = "";
  stripeOptions: any = {};
  complete = false;
  created() {
    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY?.toString() ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale,
      },
    };
    if (!this.selectedProduct) {
      store.commit("pricing/setSelected", {
        product: store.state.pricing.products[0],
        billingPeriod: SubscriptionBillingPeriod.MONTHLY,
      });
    }
  }
  mounted() {
    services.subscriptionProducts.getProducts();
  }
  close() {
    this.$emit("close");
  }
  createTenant() {
    if (!this.selectedPrice || this.name === "") {
      this.$refs.errorModal.show(this.$t("shared.missingFields"));
    } else {
      this.$refs.confirmModal.show(this.$t("settings.tenant.createConfirm"));
    }
  }
  tryRegister() {
    if (this.selectedPrice?.trialDays === 0 && this.selectedPrice?.price > 0) {
      this.$refs.cardModal.show();
    } else {
      this.register();
    }
  }
  pay() {
    createToken()
      .then((data) => {
        if (data.error) {
          this.$refs.errorModal.show(data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.$refs.cardModal.close();
      });
  }
  register(stripeCardToken = "") {
    const tenantCreateRequest: TenantCreateRequest = {
      name: this.name,
      selectedSubscription: {
        subscriptionPriceId: this.selectedPrice?.id ?? "",
        subscriptionCardToken: stripeCardToken,
        subscriptionCoupon: "",
      },
    };
    services.tenants
      .create(tenantCreateRequest)
      .then(() => {
        this.name = "";
        this.$emit("close");
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      });
  }
  get selectedProduct() {
    return store.state.pricing.selectedProduct as SubscriptionProductDto;
  }
  get getButtonText(): string {
    if (this.selectedPrice) {
      return (
        (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE ? this.$t("pricing.pay") : this.$t("pricing.subscribe")) +
        " " +
        this.priceDescription
      );
    }
    return "";
  }
  get selectedPrice(): SubscriptionPriceDto | undefined {
    if (this.selectedProduct) {
      return this.selectedProduct.prices.find((f) => f.billingPeriod === store.state.pricing.billingPeriod) ?? this.selectedProduct.prices[0];
    }
    return undefined;
  }
  get billingPeriod(): string {
    if (this.selectedPrice) {
      if (this.selectedPrice?.billingPeriod === SubscriptionBillingPeriod.ONCE) {
        return this.$t("pricing.once").toString();
      } else {
        return "/ " + this.$t("pricing." + SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] + "Short");
      }
    }
    return "";
  }
  get priceDescription() {
    return "$" + this.selectedPrice?.price + " " + this.billingPeriod;
  }
}
