































































































import Component from "vue-class-component";
import Vue from "vue";
import store from "@/store";
import MySubscriptionProducts from "@/components/core/settings/subscription/MySubscriptionProducts.vue";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import ProvidersUsage from "@/components/app/usages/ProvidersUsage.vue";
import ClientsUsage from "@/components/app/usages/ClientsUsage.vue";
import EmployeesUsage from "@/components/app/usages/EmployeesUsage.vue";
import services from "@/services";
import UserUtils from "@/utils/store/UserUtils";
import { UserDto } from "@/application/dtos/core/users/UserDto";
import Loading from "@/components/ui/loaders/Loading.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("app.sidebar.dashboard").toString(),
  },
  components: {
    MySubscriptionProducts,
    ProvidersUsage,
    ClientsUsage,
    EmployeesUsage,
    Loading,
  },
})
export default class Dashboard extends Vue {
  loading = false;
  mounted() {
    this.load();
  }
  load() {
    services.tenants.getCurrentUsage(AppUsageType.CONTRACTS);
  }
  get currentUser(): UserDto {
    return store.state.account.user ?? ({} as UserDto);
  }
  get avatarText() {
    return UserUtils.avatarText(this.currentUser);
  }
  get avatar() {
    return store.state.account.user?.avatar ?? "";
  }
}
