










































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import EmployeesListAndTable from "./EmployeesListAndTable.vue";
import { EmployeeDto } from "@/application/dtos/app/employees/EmployeeDto";
import Loading from "@/components/ui/loaders/Loading.vue";

@Component({
  components: {
    EmployeesListAndTable,
    Loading
  }
})
export default class EmployeesList extends Vue {
  loading = false;
  items: EmployeeDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.employees
      .getAll()
      .then(response => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get filteredItems(): EmployeeDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
}
